import React, { useEffect, useState, useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button } from "../../@/components/button";
import { ShoppingCart } from "lucide-react";
import { FileUp } from "lucide-react";

const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const nameOfDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Calendar = ({
  setSelectedDate,
  datesOfReceiptsUploaded,
  datesOfReceipts,
}) => {
  const ref = useRef(null);
  const [currentMonth, setCurrentMonth] = useState(() =>
    new Date().getUTCMonth()
  );
  const [daysInCurrentMonth, setDaysInCurrentMonth] = useState(() =>
    getDaysInMonth(new Date().getUTCFullYear(), new Date().getUTCMonth())
  );
  const [currentYear, setCurrentYear] = useState(() =>
    new Date().getUTCFullYear()
  );
  const [currentDate, setCurrentDate] = useState(() => new Date().getUTCDate());

  useEffect(() => {
    setDaysInCurrentMonth(getDaysInMonth(currentYear, currentMonth));
  }, [currentMonth, currentYear]);

  let daysOfMonth = [...Array(daysInCurrentMonth).keys()].map((i) => i + 1);

  const getDayOfWeek = (year, month, day) => {
    const date = new Date(Date.UTC(year, month, day));
    return date.getUTCDay(); // Returns a number (0 for Sunday, 1 for Monday, etc.)
  };

  const getDateString = (element) => {
    const yearString = currentYear.toString();
    const monthString = (currentMonth + 1).toString().padStart(2, "0");
    const dayString = element.toString().padStart(2, "0");

    // Concatenate the formatted strings to form the selected date
    return `${yearString}-${monthString}-${dayString}`;
  };

  const handleDateClick = (element) => {
    // setReceiptAvailable(false);
    setCurrentDate(element); // Update current date
    setSelectedDate(() => {
      return getDateString(element);
    });
    // Scroll to the selected date
    ref.current.querySelector(`[data-date="${element}"]`).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };


  // Set the Calendar to today's date by default
  useEffect(() => {
    handleTodayClick();
  }, []);

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentYear(today.getUTCFullYear());
    setCurrentMonth(today.getUTCMonth());
    setCurrentDate(today.getUTCDate());
    setSelectedDate(() => {
      return getDateString(today.getUTCDate());
    });
    // Scroll to today's date
    ref.current
      .querySelector(`[data-date="${today.getUTCDate()}"]`)
      .scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  return (
    <div style={{ maxWidth: "700px", margin: "0 auto", padding: "10px" }}>
      <div className="flex gap-3 items-center justify-around font-bold text-2xl">
        <div
          onClick={() =>
            setCurrentMonth((prev) => (prev > 0 ? prev - 1 : prev))
          }
          className="shadow-lg rounded-full cursor-pointer w-fit"
        >
          <KeyboardArrowLeftIcon fontSize="large" />
        </div>
        <div className="text-center">
          <p className="text-gray-400 font-normal text-base">
            View your past receipts
          </p>
          <div>
            <p>{months[currentMonth]}</p>
          </div>
        </div>
        <div
          onClick={() =>
            setCurrentMonth((prev) => (prev < 11 ? prev + 1 : prev))
          }
          className="shadow-lg rounded-full cursor-pointer w-fit"
        >
          <KeyboardArrowRightIcon fontSize="large" />
        </div>
      </div>

      <div
        ref={ref}
        className="flex gap-1 lg:gap-4 mt-4 text-center w-11/12 md:w-2/3 mb-7 overflow-x-scroll m-auto calendar-scroll"
      >
        {daysOfMonth.map((element, idx) => {
          return (
            <div
              key={idx}
              onClick={() => handleDateClick(element)} // Call handleDateClick when a date is clicked
              data-date={element} // Add data attribute for scrolling
              className={` p-3 min-w-[50px] relative cursor-pointer rounded-lg ${currentDate === element ? "bg-black text-white " : "hover:bg-gray-100"}`}
            >
              <div className="left-6 top-2 m-auto flex">
                <div
                  className={`${datesOfReceipts?.some((date) => date && date.startsWith(getDateString(element))) ? "text-orange-500" : "text-transparent"} mr-1`}
                >
                  <ShoppingCart className="h-3 w-3" />
                </div>
                <div
                  className={`${datesOfReceiptsUploaded?.some((date) => date && date.startsWith(getDateString(element))) ? "text-grey" : "text-transparent"}`}
                >
                  <FileUp className="h-3 w-3" />
                </div>
              </div>

              <p
                className={`${currentDate === element ? " text-white " : "text-gray-400"}`}
              >
                {nameOfDays[getDayOfWeek(currentYear, currentMonth, element)]}
              </p>
              <p className="font-bold">{element}</p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Button onClick={handleTodayClick}>Today</Button>
      </div>
    </div>
  );
};

export default Calendar;
