import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="max-w-4xl mx-auto mb-8 mt-16 sm:mt-24 sm:mb-6 container text-base border-t border-gray-200 pt-8">
      <div className="flex flex-col md:flex-row gap-4 md:gap-8 justify-between items-start w-full pt-8 px-6">
        <div className="w-1/6">
          <Link
            to="/"
            className="flex flex-row justify-start items-center gap-2 p-2 -m-2"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 300 300"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="150"
                cy="150"
                r="140"
                stroke="black"
                stroke-width="10"
                fill="white"
              />

              <path
                d="M160 100V200M140 100V200M180 130H120M180 170H120"
                stroke="black"
                stroke-width="15"
                stroke-linecap="round"
              />

              <path
                d="M150 60C150 60 120 90 120 120C120 150 150 150 150 150C150 150 180 150 180 120C180 90 150 60 150 60Z"
                fill="black"
              />
              <path
                d="M150 150V220"
                stroke="black"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </Link>
        </div>
        <div className="flex-grow mt-4 ml-0 sm:-ml-12 md:mt-0">
          <div className="grid grid-cols-1 mb-16 md:grid-cols-4 gap-4 md:gap-8">
            <div>
              <h3 className="text-black text-base font-semibold mb-2">
                Resources
              </h3>
              <ul>
                <li>
                  <Link
                    to="/guide"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Guide
                  </Link>
                </li>
                <li>
                  <Link
                    to="/affiliate"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Affiliate
                  </Link>
                </li>
                <li>
                  <Link
                    to="#faq-section"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-black text-base font-semibold mb-2">
                Company
              </h3>
              <ul>
                <li>
                  <a
                    href="/roadmap"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Roadmap
                  </a>
                </li>
                <li>
                  <a
                    href="/jobs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:support@fiveout.com"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    id="intercom_support"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-black text-base font-semibold mb-2">
                Socials
              </h3>
              <ul>
                <li>
                  <a
                    href="https://twitter.com/fiveoutapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    X (Twitter)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@fiveoutapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    TikTok
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/fiveoutapp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/fiveout/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-black text-base font-semibold mb-2">Legal</h3>
              <ul>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Terms
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="text-textGray4 cursor-pointer transition-all ease duration-200 hover:text-blackText hover:text-[#000000] block mb-1"
                  >
                    Privacy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/6 flex flex-col items-center sm:items-end gap-3 mb-10 sm:mb-0">
          <p className="text-sm">© 2024 FiveOut Ltd</p>
          <div className="flex flex-row gap-3">
            <a
              href="https://instagram.com/fiveoutapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-instagram"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
              </svg>
            </a>
            <a
              href="mailto:aman@5outapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.875 2.5C0.839844 2.5 0 3.33984 0 4.375C0 4.96484 0.277344 5.51953 0.75 5.875L9.25 12.25C9.69531 12.582 10.3047 12.582 10.75 12.25L19.25 5.875C19.7227 5.51953 20 4.96484 20 4.375C20 3.33984 19.1602 2.5 18.125 2.5H1.875ZM0 6.875V15C0 16.3789 1.12109 17.5 2.5 17.5H17.5C18.8789 17.5 20 16.3789 20 15V6.875L11.5 13.25C10.6094 13.918 9.39063 13.918 8.5 13.25L0 6.875Z"
                  fill="#000000"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}