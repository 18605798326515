import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardFooter,
} from "../../../@/components/card";
import { db } from "../../../firebase/config";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { Button } from "../../../@/components/button";

export function DuplicateRemover() {
  const [duplicatesCount, setDuplicatesCount] = useState(0);

  useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
        const userUid = localStorage.getItem("userUid");
        const userReceiptsRef = collection(db, "allUserReceipts");
        const userRef = doc(db, "users", userUid);
        const q = query(userReceiptsRef, where("userRef", "==", userRef));
        const querySnapshot = await getDocs(q);

        const documents = querySnapshot.docs;
        const hashedImageUrls = documents.map(
          (doc) => doc.data().image_hash.sha256_hash
        );

        const uniqueHashedImageUrls = [...new Set(hashedImageUrls)];

        const duplicateCount =
          hashedImageUrls.length - uniqueHashedImageUrls.length;
        setDuplicatesCount(duplicateCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAndProcessData();
  }, []);

  return (
    <Card>
      <CardHeader className="pb-2">
        <CardDescription>Duplicates</CardDescription>
        <CardTitle className="text-4xl">{duplicatesCount}</CardTitle>
      </CardHeader>
      <CardFooter>
      <Button variant="outline">Remove</Button>
      </CardFooter>
    </Card>
  );
}
