// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, getMetadata } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCaHg7Z8DVqwl5jYYMUCFygurcZ-Z8cHn4",
  // authDomain: "mandi-3e35b.firebaseapp.com",
  authDomain: "mandinow.com",
  projectId: "mandi-3e35b",
  storageBucket: "mandi-3e35b.appspot.com",
  messagingSenderId: "182206783951",
  appId: "1:182206783951:web:fc2bb8289f5bcf415732ba",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase App Check
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfDee4pAAAAAMwH_VHCDrlD_z82dKwk0RgZRL0h"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Google Authentication Provider
const provider = new GoogleAuthProvider();

// add code here which takes in image_url and if it is having expired token then regereate the image url with the new token so that it can be viewed in the frontment

// the function takes in image_url and returns the new image url with the new token
//

const getFirebaseImageUrl = async (imagePath) => {
  const storage = getStorage();
  const storageRef = ref(storage, imagePath);

  try {
    // Get the download URL of the image
    const url = await getDownloadURL(storageRef);

    // Check if the URL is expired
    const metadata = await getMetadata(storageRef);
    const expiration = metadata.timeCreated;
    const currentTime = Date.now();
    const expirationTime = new Date(expiration).getTime() + 24 * 60 * 60 * 1000; // 24 hours

    if (currentTime > expirationTime) {
      // Create a new URL if the current URL is expired
      const newUrl = await getDownloadURL(storageRef);
      return newUrl;
    }

    return url;
  } catch (error) {
    console.error("Error getting Firebase image URL:", error);
    return null;
  }
};

async function fetchTextData(filePath) {
  const storage = getStorage();
  const fileRef = ref(storage, filePath);
  try {
    const url = await getDownloadURL(fileRef);
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const textData = await response.text();
    return textData;
  } catch (error) {
    console.error("There was a problem fetching the text file:", error);
    return null;
  }
}

export { auth, provider, db, getFirebaseImageUrl, fetchTextData };
