import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../components/common/Loading";
import HowItWorks from "../components/track-foods/homepage/HowItWorks.jsx";
import CallToAction from "../components/track-foods/homepage/CallToAction.jsx";
import FAQs from "../components/track-foods/homepage/FAQs.jsx";
import { ExampleSection } from "../components/track-foods/homepage/ExampleReceipt.jsx";
import { renderUploadedImage } from "../utils/imageRenders.js";
import { uploadImage } from "../utils/api.js";

import { useRef, Suspense } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Environment, useGLTF } from "@react-three/drei";


const FloatingBanana = ({ z, scrollSpeed, isAtEnd }) => {
  const bananaRef = useRef();
  const { nodes, materials } = useGLTF("./models/banana-v1-transformed.glb");

  const { viewport, camera } = useThree();
  const { height, width } = viewport.getCurrentViewport(camera, [0, 0, z]);

  const [data] = useState({
    x: THREE.MathUtils.randFloatSpread(2), // -1 to 1
    y: THREE.MathUtils.randFloatSpread(height), // -1 to 1
    rX: Math.random() * Math.PI,
    rY: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI,
  });

  useFrame((state) => {
    bananaRef.current.rotation.set(
      (data.rX += 0.001),
      (data.rY += 0.001),
      (data.rZ += 0.001)
    );
    
    // Adjust the y-position based on scroll speed
    const baseSpeed = 0.045;
    const minSpeed = 0.02;
    const maxSpeed = 0.3;
    const speedMultiplier = isAtEnd ? 1 : 1 + scrollSpeed * 5; // Reset to base speed if at end
    const adjustedSpeed = THREE.MathUtils.clamp(baseSpeed * speedMultiplier, minSpeed, maxSpeed);
    // print the adjusted speed
    bananaRef.current.position.set(data.x * width, (data.y += adjustedSpeed), z);
    if (data.y > height) data.y = -height;
  });
  return (
    <mesh
      ref={bananaRef}
      geometry={nodes.banana.geometry}
      material={materials.skin}
      rotation={[-Math.PI / 2, 0, 0]}
      material-emissive="orange"
    />
  );
};
// Render image upload form
const renderImageUploadForm = (handleImageChange, buttonText = "Try Now") => (
  <div className="text-center ">
    <input
      type="file"
      id="image-upload"
      accept="image/jpeg, image/jpg, image/png, image/heic"
      onChange={(event) => {
        event.preventDefault(); // Prevent Safari from reloading the webpage
        handleImageChange(event);
      }}
      className="hidden"
    />
    <label
      htmlFor="image-upload"
      className="text-white cursor-pointer bg-black border-0 outline-none py-2 px-6 w-fit hover:px-8 transition-all rounded-3xl"
    >
      {buttonText}
    </label>
  </div>
);

const HeroText = () => (
  <section>
    <h1 className="md:text-8xl text-4xl w-full md:w-full font-medium">
      Track your groceries, save money and get healthy
    </h1>
    <div className="md:text-4xl mt-12 text-xl w-full md:w-full">
      Upload an image of your grocery receipt to get started
    </div>
  </section>
);

export function UploadImage({ buttonText = "Try Now" }) {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false); // New state to track if image is submitted
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [errorOccurred, setErrorOccurred] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]); // This effect runs when location changes

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    // check the datetime stamp of selectedImage
    const date = new Date(selectedImage.lastModified);
    // now check if the photo was taken in last 30 seconds
    const now = new Date();
    const diff = now - date;

    if (diff < 30000) {
      console.log("Image was taken in last 30 seconds");
      const uniqueFileName = `${selectedImage.name}-${new Date().getTime()}`; // Append timestamp to ensure uniqueness
      setUploadedFileName(uniqueFileName);
    } else {
      setUploadedFileName(selectedImage.name); // Use the original file name if not taken by the camera
    }
    setImage(selectedImage);
    setImageUrl(URL.createObjectURL(selectedImage));
  };

  const handleSubmit = () => {
    if (image) {
      setLoading(true);
      const checkReceiptProcessed = localStorage.getItem(uploadedFileName);
      if (checkReceiptProcessed) {
        console.log("Receipt has already been processed");
      } else {
        console.log("Processing the receipt...");
      }

      uploadImage(uploadedFileName, image)
        .then((response) => {
          setLoading(false);
          localStorage.setItem(uploadedFileName, response.data.document_id);
          console.log("image uploaded successfully!");
          navigate(`/track-food/result/${uploadedFileName}`);
          // After successful image upload
          localStorage.setItem("imageUploaded", "true");
          console.log("API call successful!"); // Add message for successful API call
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          setErrorOccurred(true);
          setLoading(false);
        });
      setSubmitted(true); // Set submitted to true after image is submitted
    }
  };

  // Main component rendering logic
  return (
    <div>
      {errorOccurred && (
        <p className=" text-red-500 m-4 text-center">
          There was an error in processing yea receipt we are actively working
          on it and will improve our systems. Thank you for your feedback.
        </p>
      )}
      {/* Render uploaded image */}
      {renderUploadedImage(imageUrl)}

      {/* Render upload form */}
      {!imageUrl && <>{renderImageUploadForm(handleImageChange, buttonText)}</>}

      {/* Render submit button */}
      {imageUrl && !submitted && (
        <div className="text-center">
          <button
            onClick={handleSubmit}
            className="text-white cursor-pointer bg-black border-0 outline-none py-2 px-6 w-fit hover:px-8 transition-all rounded-3xl"
          >
            Submit
          </button>
        </div>
      )}
      {/* Show submission confirmation */}
      {submitted && (
        <p className="text-center">
          Your image has been submitted. You can now proceed to the next step.
        </p>
      )}
      {/* @file_context_0 */}
      {loading && (
        <Loading
          textList={{
            "Uploading image...": 3000,
            "Scanning image...": 10000,
            "Processing receipt...": 30000,
            "Please wait a bit more...": 5000,
          }}
        />
      )}
    </div>
  );
}

// https://youtu.be/2tBoiQXUmw4?si=58WlYQnQ8V5QCbGq
const YouTubeVideo = ({ videoId }) => {
  return (
    <div className="video-wrapper my-16 mx-auto w-full px-4 sm:px-6 md:px-8 lg:px-16">
      <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default function HomeNew() {
  const count = 30;
  const depth = 70;
  const [scrollSpeed, setScrollSpeed] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    let lastScrollTime = Date.now();
    let scrollTimer;
  
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      const now = Date.now();
      const delta = Math.abs(st - lastScrollTop);
      const time = now - lastScrollTime;
      const speed = delta / time;
  
      setScrollSpeed(speed);
      setIsScrolling(true);
  
      // Check if at the top or bottom of the page
      const isAtTop = st <= 0;
      const isAtBottom = st + window.innerHeight >= document.documentElement.scrollHeight;
      setIsAtEnd(isAtTop || isAtBottom);
  
      lastScrollTop = st <= 0 ? 0 : st;
      lastScrollTime = now;
  
      // Clear the existing timer and set a new one
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        setIsScrolling(false);
        setScrollSpeed(0);
      }, 500); // Adjust this value to change how quickly it detects that scrolling has stopped
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimer);
    };
  }, []);

  // Main component rendering logic
  return (
    <>
      <Canvas
        gl={{ alpha: false }}
        camera={{ near: 0.01, far: 110, fov: 30 }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1,
        }}
      >
        <color attach="background" args={["#ffbf40"]} />
        <spotLight position={[10, 10, 10]} intensity={1} />
        <Suspense fallback={null}>
          <Environment preset="sunset" />
          {Array.from({ length: count }, (_, idx) => (
            <FloatingBanana key={idx} z={-(idx / count) * depth - 20} scrollSpeed={scrollSpeed} isAtEnd={isAtEnd} />
          ))}
        </Suspense>
      </Canvas>
      <div className="relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2  max-w-7xl mx-auto ">
          <div className="p-4">
            <HeroText  />
          </div>
          <div className="md:col-span-1 flex items-center justify-center">
            <div className="md:outline outline-black md:pt-40 md:px-4  rounded-lg">
              <div className="p-4"> {/* Adding a gap */}
                <UploadImage buttonText="Try Now" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 p-4">
          <YouTubeVideo videoId="zBDHjKCdHaI" />
        </div>
        <div className="my-28"></div>
        <div>
          <ExampleSection />
          <HowItWorks />
          <CallToAction />
          <FAQs />
          <CallToAction />
        </div>
      </div>
    </>
  );
}