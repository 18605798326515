// config.js
const isLocalEnvironment = process.env.NODE_ENV === 'development';

// const baseApiUrl = "https://grocery-tracker-66k7fisxaq-ew.a.run.app";
const baseApiUrl = isLocalEnvironment
  ? "http://localhost:8080"
  : "https://grocery-tracker-66k7fisxaq-ew.a.run.app";


const config = {
  // apiUrl: "https://website-backend-lime.vercel.app",
  apiUrl: isLocalEnvironment
    ? 'http://localhost:3001'
    : "https://website-backend-lime.vercel.app",
  adminEmails: ["aman.2k4@gmail.com", "goingoutguru@gmail.com"],
  apiEndpoints: {
    uploadImage: `${baseApiUrl}/upload-image`,
    processImage: `${baseApiUrl}/process-image/`,
    extractText: `${baseApiUrl}/extract-text/`,
    textLlmParse: `${baseApiUrl}/text-llm-parse/`,
    imageLlmOpenai: `${baseApiUrl}/image-llm-openai/`,
    sendItemsToBigquery: `${baseApiUrl}/send-items-to-bigquery/`,
    nutritionistFeedback: `${baseApiUrl}/call-llm/`,
  },
};

export default config;
