import React, { useState, useEffect } from "react";
import { formatedDatetime } from "../../utils/dataFormat";
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardFooter,
  Card,
  CardContent,
} from "../../@/components/card";
import { AspectRatio } from "../../@/components/aspect-ratio";
import { ShoppingCart, FileUp, ZoomIn, ZoomOut, ShoppingBasket } from "lucide-react";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";
import { calculateTotalCategories } from "../../utils/dataStats";
import { Button } from "../../@/components/button";
import { getFirebaseImageUrl, fetchTextData } from "../../firebase/config";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../@/components/dialog";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../../firebase/config'; // Assuming you have the auth object exported from your Firebase configuration
import config from "../../config";

const ImageTextViewer = ({ imageUrl, textFileUrl }) => {
  const [imageContent, setImageContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [textUrl, setTextUrl] = useState(null);
  const [zoom, setZoom] = useState(1);

  

  useEffect(() => {
    const fetchContents = async () => {
      const imgUrl = await getFirebaseImageUrl(imageUrl);
      setImageContent(imgUrl);

      try {
        const textUrl = await getFirebaseImageUrl(textFileUrl);
        setTextUrl(textUrl);
        const textData = await fetchTextData(textFileUrl);
        setTextContent(textData);
      } catch (error) {
        console.error("Error fetching text content:", error);
        setTextContent("Error loading text content");
      }
    };

    fetchContents();
  }, [imageUrl, textFileUrl]);

  const handleZoomIn = () => {
    setZoom(prevZoom => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => Math.max(0.1, prevZoom - 0.1));
  };


  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>View Receipt and Text</Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="mb-2 text-lg font-semibold">Receipt Image</h3>
            <div className="flex items-center mb-2">
              <Button onClick={handleZoomOut}><ZoomOut size={16} /></Button>
              <span className="mx-2">{Math.round(zoom * 100)}%</span>
              <Button onClick={handleZoomIn}><ZoomIn size={16} /></Button>
            </div>
            <div className="overflow-auto max-h-[60vh] border rounded">
              {imageContent && (
                <img
                  src={imageContent}
                  alt="Receipt"
                  style={{
                    transform: `scale(${zoom})`,
                    transformOrigin: 'top left',
                    transition: 'transform 0.3s ease',
                    width: '100%',
                  }}
                />
              )}
            </div>
            <Button className="mt-2" onClick={() => window.open(imageContent)}>
              Open in New Window
            </Button>
          </div>
          <div>
            <h3 className="mb-2 text-lg font-semibold">Processed Text</h3>
            <pre className="whitespace-pre-wrap bg-gray-100 p-4 rounded max-h-[60vh] overflow-y-auto">
              {textContent}
            </pre>
            {textUrl && (
              <Button onClick={() => window.open(textUrl)}>
                Open text file
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const RenderImageButton = ({ imageUrl }) => {
  const [workingUrl, setWorkingUrl] = useState(null);
  

  useEffect(() => {
    const fetchWorkingUrl = async () => {
      const url = await getFirebaseImageUrl(imageUrl);
      setWorkingUrl(url);
    };

    fetchWorkingUrl();
  }, [imageUrl]);

  return <Button onClick={() => window.open(workingUrl)}>View Receipt</Button>;
};

function SupermarketCard({ renderedReceiptId }) {
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [name, setName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user] = useAuthState(auth); // Check if the user is logged in

  useEffect(() => {
    const checkAuthStatus = () => {
      
      if (user) {
        // Check if the user is admin
        const adminEmails = config.adminEmails;
        // add null check for adminEmails
        if (adminEmails) {
          const isAdmin = adminEmails.includes(user.email);
          setIsAdmin(isAdmin);
        }
      }
    };

    checkAuthStatus();
  }, [user]);

  useEffect(() => {
    const fetchReceiptData = async () => {
      const data = await fetchReceiptDetails(
        renderedReceiptId,
        "allUserReceipts"
      );
      setReceiptDetails(data);
    };

    fetchReceiptData();
  }, [renderedReceiptId]);

  if (!receiptDetails) {
    return <div>Loading.ss..</div>; // Render a loading indicator while data is being fetched
  }

  return (
    <>
      <Card className="card">
        {" "}
        {/* Centering the card on big screens with a maximum width of 400 */}
        <CardHeader className="font-bold">
          <CardTitle>
            <input
              type="text"
              value={receiptDetails?.processed_data?.store_name}
              onChange={(e) => setName(e.target.value)}
            />
          </CardTitle>
        </CardHeader>
        <CardContent>
          {" "}
          {/* Centering the content inside the card */}
          <div align="left">
            <p className="text-gray-400">
              <ShoppingCart className="inline-block h-5 w-5 text-gray-400" />{" "}
              {formatedDatetime(
                receiptDetails?.processed_data?.datetime ?? "Unknown"
              )}
            </p>
            <p className="text-gray-400">
              <FileUp className="inline-block h-5 w-5 text-gray-400" />{" "}
              {formatedDatetime(receiptDetails?.request_timestamp ?? "Unknown")}
            </p>
            <p className="font-[500] text-gray-500">
              <ShoppingBasket className="inline-block h-5 w-5 text-gray-500" />{" "}
              {receiptDetails?.processed_data?.items.length} items
            </p>
            <p className="font-[500] text-gray-500">
              In{" "}
              {calculateTotalCategories(receiptDetails?.processed_data.items)}{" "}
              categoriesss
            </p>
            <p className="font-[500] text-gray-500">
              Total: {receiptDetails?.processed_data?.total_amount}
            </p>
          </div>
        </CardContent>
        <CardFooter>
          <RenderImageButton imageUrl={receiptDetails?.image_url} />
          {/* <RenderImageButton imageUrl={receiptDetails?.processed_text_file} /> */}
        </CardFooter>
      </Card>

      {isAdmin && (
        <div className="mt-4">
          <ImageTextViewer
            imageUrl={receiptDetails?.image_url}
            textFileUrl={receiptDetails?.processed_text_file}
          />
        </div>
      )}
    </>
  );
}

export default SupermarketCard;
