import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import RenderReceipts from "./RenderReceipts";
import Dashboard from "../../components/track-foods/dashboard/Dashboard";
import { fetchUserReceipts } from "../../utils/firestoreUtils";
import { setUniqueReceiptData } from "../../utils/dataStats";

const FirestoreDocumentDetails = ({ setRenderedReceiptId, user }) => {
  const [documentDetails, setDocumentDetails] = useState({});
  const [datesOfReceiptsUploaded, setDatesOfReceiptsUploaded] = useState([]);
  const [datesOfReceipts, setDatesOfReceipts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const userUid = localStorage.getItem("userUid");
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const fetchData = async () => {
    try {
      const details = await fetchUserReceipts(userUid, "allUserReceipts");
      setDocumentDetails(details);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setUniqueReceiptData(documentDetails, setDatesOfReceiptsUploaded, setDatesOfReceipts);
  }, [documentDetails]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div >
      {documentDetails && Object.keys(documentDetails).length === 0 ? (
        <p className="text-center">You have no data so far...</p>
      ) : (
        <div>
          
          <Dashboard />
          
          <Calendar
            setSelectedDate={setSelectedDate}
            datesOfReceiptsUploaded={datesOfReceiptsUploaded}
            datesOfReceipts={datesOfReceipts}
          />

          {selectedDate && (
            <RenderReceipts
              selectedDate={selectedDate}
              setRenderedReceiptId={setRenderedReceiptId}
              user={user}
            />
          )}
        </div>
      )}
      <div>
        <p>
          Logged in as: {user.email}
        </p>
      </div>
    </div>
  );
};

export default FirestoreDocumentDetails;