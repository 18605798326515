import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { auth } from '../../firebase/config'; // Assuming you have the auth object exported from your Firebase configuration
import {Button} from '../../@/components/button';
import { useAuthState } from "react-firebase-hooks/auth";

const LogOut = () => {
  const navigate = useNavigate(); // Access the navigate function
  const [user] = useAuthState(auth); // Check if the user is logged in

  const handleLogout = () => {
    localStorage.removeItem("email");
    // You should also sign out the user from Firebase Authentication
    auth.signOut().then(() => {
      // Redirect to the main page
      navigate("/", { replace: true });
    });
  };

  return (
    <div className="text-center max-w-[400px] mx-auto">
      {user && (
        <p className="text-sm text-muted-foreground">Hi 👋🏽  {user.email}.</p>
      )}
      {user && (
        <Button variant="outline" onClick={handleLogout}>Logout</Button>
      )}
    </div>
  );
};

export default LogOut;
