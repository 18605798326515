import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";

export default function MoreModal({feedback,setFeedback,feedbackFromInput,setFeedbackFromInput,handleSubmit}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const sendFeedback=()=>{
    handleSubmit(feedback);
    handleClose();
  }
  return (
    <div>
      <button
        onClick={handleOpen}
        className="rounded-lg border border-token-border-light px-3 py-1 text-sm text-token-text-secondary hover:bg-slate-100 "
      >
        More...
      </button>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <div>
          <div
            role="dialog"
            id="radix-:rc5:"
            aria-describedby="radix-:rc7:"
            aria-labelledby="radix-:rc6:"
            data-state="open"
            className="popover relative left-1/2 col-auto col-start-2 row-auto row-start-2 w-full -translate-x-1/2 rounded-xl bg-token-main-surface-primary text-left shadow-xl transition-all flex flex-col focus:outline-none max-w-lg bg-white"
            tabIndex="-1"
          >
            <div className="px-4 pb-4 pt-5 sm:p-6 flex items-center justify-between border-b border-black/10 dark:border-white/10">
              <div className="flex">
                <div className="flex items-center">
                  <div className="flex grow flex-col gap-1">
                    <h2
                      id="radix-:rc6:"
                      as="h3"
                      className="text-lg font-medium leading-6 text-token-text-primary "
                    >
                      Provide additional feedback
                    </h2>
                  </div>
                </div>
              </div>
              <button
              onClick={handleClose}
                className=""
                fdprocessedid="z8mc7o"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-grow overflow-y-auto p-4 sm:p-6">
              <div className="flex flex-wrap gap-3">
                {feedbackArray.map((value, idx) => (
                  <button
                    key={idx}
                    onClick={()=>setFeedback(value)}
                    className={`rounded-lg border border-token-border-light px-3 py-1 text-sm text-token-text-secondary ${feedback == value ? 'hover:bg-black text-white' : 'hover:bg-slate-100'} ${feedback == value && 'bg-black text-white'}`}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="rounded-md border shadow-none border-token-border-medium px-3 py-2 focus-within:border-token-border-xheavy focus-within:ring-1 focus-within:ring-token-text-secondary mt-6">
                <label
                  htmlFor="feedback"
                  className="block text-xs font-medium text-token-text-primary"
                ></label>
                <div className="relative">
                  <input
                    type="text"
                    name="feedback"
                    id="feedback"
                    className="block w-full border-0 p-0 placeholder-gray-500 shadow-none outline-none focus-within:shadow-none focus-within:outline-none focus-within:ring-0 focus:border-none focus:ring-0 sm:text-sm bg-token-main-surface-primary text-token-text-primary"
                    placeholder="(Optional) Feel free to add specific details"
                    value={feedbackFromInput}
                    onChange={(e) => setFeedbackFromInput(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3 sm:flex-row-reverse mt-5 sm:mt-4">
                <button onClick={sendFeedback} className=" hover:bg-green-600  btn relative btn-primary bg-green-500 text-white px-3 py-1 rounded-2xl">
                  <div className="flex w-full gap-2 items-center justify-center">
                    Submit
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const feedbackArray = [
  `Don't like the style`,
  `  Not factually correct`,
  `Didn't fully follow instructions`,
  ` Refused when it shouldn't have`,
  `Being lazy`,
  `Other`,
];
