import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "../../App.css";
import Footer from "./Footer";
import { auth } from "../../firebase/config"; // Assuming you have the auth object exported from your Firebase configuration
import { useNavigate } from "react-router-dom";
import { Button } from "../../@/components/button";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Navbar() {
  const [user] = useAuthState(auth); // Check if the user is logged in
  const navigate = useNavigate(); // Access the navigate function
  const handleLogout = () => {
    localStorage.removeItem("email");
    // You should also sign out the user from Firebase Authentication
    auth.signOut().then(() => {
      // Redirect to the main page
      navigate("/", { replace: true });
    });
  };

  return (
    <>
      <nav className="navbar mb-12 lg:px-8">
        <div className="container">
          <Link to={"/"}>
            <div className="logo text-2xl font-bold">FiveOut</div>
          </Link>
  
          <div className="hidden md:flex">
            <NavLink to="/#demo" className="hover:scale-125 transition-all">
              Demo
            </NavLink>
          </div>
  
          <div className="hidden md:flex">
            <NavLink to="/#how-it-works" className="hover:scale-125 transition-all">
              How It Works
            </NavLink>
          </div>
  
          <div className="hidden md:flex">
            <NavLink to="/#faqs" className="hover:scale-125 transition-all">
              FAQs
            </NavLink>
          </div>
  
          <div >
            <Button variant="link" className="hover:underline">
              {user ? (
                <NavLink to={`/track-food/dashboard/${user.uid}`} >
                  Dashboard
                </NavLink>
              ) : (
                <NavLink to="/" >
                  Track your Food
                </NavLink>
              )}
            </Button>
  
            <Button className="hover:underline ">
              {user ? (
                <NavLink  onClick={handleLogout}>
                  Logout
                </NavLink>
              ) : (
                <NavLink to="/login" >
                  Login
                </NavLink>
              )}
            </Button>
          </div>
        </div>
      </nav>
      
      <Outlet />
      <Footer />
    </>
  );
}