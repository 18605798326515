import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../components/common/Loading";
import config from "../config.js";
// import OgImages from "../components/track-foods/homepage/OgImages.jsx";
import OGComparison from "../components/track-foods/homepage/OGComparison.jsx";
import HowItWorks from "../components/track-foods/homepage/HowItWorks.jsx";
import CallToAction from "../components/track-foods/homepage/CallToAction.jsx";
import FAQs from "../components/track-foods/homepage/FAQs.jsx";
import { ExampleSection } from "../components/track-foods/homepage/ExampleReceipt.jsx";
import { renderUploadedImage } from "../utils/imageRenders.js";
import { uploadImage } from "../utils/api.js";

import { Button } from "../@/components/button";
import { PiggyBank } from 'lucide-react';
// Construct specific API endpoints
const uploadImageEndpoint = `${config.apiEndpoints.uploadImage}`;

// Render image upload form
const renderImageUploadForm = (handleImageChange, buttonText = "Try Now") => (
  <div className="text-center ">
    <input
      type="file"
      id="image-upload"
      accept="image/jpeg, image/jpg, image/png, image/heic"
      onChange={(event) => {
        event.preventDefault(); // Prevent Safari from reloading the webpage
        handleImageChange(event);
      }}
      className="hidden"
    />
    <label
      htmlFor="image-upload"
      className="text-white cursor-pointer bg-black border-0 outline-none py-2 px-6 w-fit hover:px-8 transition-all rounded-3xl"
    >
      {buttonText}
    </label>
  </div>
);



const HeroText = () => (
  <div className="px-4 m-auto mb-12 grid gap-10 place-content-center my-4 relative">
    <>
      <Button variant="outline" className="mb-4 mx-auto rounded-full">
        <PiggyBank className="mr-2 h-4 w-4" /> Currently Bootstrapped
      </Button>
      <h1 className="text-4xl font-semibold sm:text-5xl text-center">
        We help you <span className="shiny-text">save money</span> and get{" "}
        <span className="healthy-text">healthier</span>
      </h1>
      <p className="text-lg sm:text-2xl text-center font-light">
        Start by uploading an image of your grocery bill
      </p>
    </>
  </div>
);

export function UploadImage({ buttonText = "Try Now" }) {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false); // New state to track if image is submitted
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [errorOccurred, setErrorOccurred] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]); // This effect runs when location changes

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    // check the datetime stamp of selectedImage
    const date = new Date(selectedImage.lastModified);
    // now check if the photo was taken in last 30 seconds
    const now = new Date();
    const diff = now - date;

    if (diff < 30000) {
      console.log("Image was taken in last 30 seconds");
      const uniqueFileName = `${selectedImage.name}-${new Date().getTime()}`; // Append timestamp to ensure uniqueness
      setUploadedFileName(uniqueFileName);
    } else {
      setUploadedFileName(selectedImage.name); // Use the original file name if not taken by the camera
    }
    setImage(selectedImage);
    setImageUrl(URL.createObjectURL(selectedImage));
  };

  const handleSubmit = () => {
    if (image) {
      setLoading(true);
      const checkReceiptProcessed = localStorage.getItem(uploadedFileName);
      if (checkReceiptProcessed) {
        console.log("Receipt has already been processed");
      } else {
        console.log("Processing the receipt...");
      }

      uploadImage(uploadedFileName, image)
        .then((response) => {
          setLoading(false);
          localStorage.setItem(uploadedFileName, response.data.document_id);
          console.log("image uploaded successfully!");
          navigate(`/track-food/result/${uploadedFileName}`);
          // After successful image upload
          localStorage.setItem("imageUploaded", "true");
          console.log("API call successful!"); // Add message for successful API call
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          setErrorOccurred(true);
          setLoading(false);
        });
      setSubmitted(true); // Set submitted to true after image is submitted
    }
  };

  // Main component rendering logic
  return (
    <div>
      {errorOccurred && (
        <p className=" text-red-500 m-4 text-center">
          There was an error in processing yea receipt we are actively working
          on it and will improve our systems. Thank you for your feedback.
        </p>
      )}
      {/* Render uploaded image */}
      {renderUploadedImage(imageUrl)}

      {/* Render upload form */}
      {!imageUrl && <>{renderImageUploadForm(handleImageChange, buttonText)}</>}

      {/* Render submit button */}
      {imageUrl && !submitted && (
        <div className="text-center">
          <button
            onClick={handleSubmit}
            className="text-white cursor-pointer bg-black border-0 outline-none py-2 px-6 w-fit hover:px-8 transition-all rounded-3xl"
          >
            Submit
          </button>
        </div>
      )}
      {/* Show submission confirmation */}
      {submitted && (
        <p className="text-center">
          Your image has been submitted. You can now proceed to the next step.
        </p>
      )}
      {/* @file_context_0 */}
      {loading && (
        <Loading
          textList={{
            "Uploading image...": 3000,
            "Scanning image...": 10000,
            "Processing receipt...": 30000,
            "Please wait a bit more...": 5000,
          }}
        />
      )}
    </div>
  );
}
// https://youtu.be/2tBoiQXUmw4?si=58WlYQnQ8V5QCbGq
const YouTubeVideo = ({ videoId }) => {
  return (
    <div className="video-wrapper my-16 mx-auto w-full px-4 sm:px-6 md:px-8 lg:px-16">
      <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default function TrackFoods() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]); // This effect runs when location changes

  // Main component rendering logic
  return (
    <div >
      <HeroText />
      <UploadImage buttonText="Try Now" />


      {/* Terms and conditions */}
      {/* <p className="w-10/12 m-auto text-center mb-6">
        By uploading an image or URL you agree to our Terms of Service. To learn
        more about how FiveOut handles your personal data, check our Privacy
        Policy.
      </p> */}

      <div className="my-32"></div>
      {/* Center-aligned, smaller YouTube video */}
      <div className="flex justify-center">
        <YouTubeVideo videoId="zBDHjKCdHaI" />
      </div>
      <div className="my-28"></div>

      <div>
        {/* <OgImages /> */}
        <ExampleSection />

        {/* <OGComparison /> */}
        <HowItWorks />

        <CallToAction />
        <FAQs />
        <CallToAction />
      </div>
    </div>
  );
}
