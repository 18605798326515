import Chart from "chart.js/auto"; // Import Chart.js as a module
import React, { useEffect, useRef, useState } from "react";
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardFooter,
  Card,
  CardContent,
} from "../../@/components/card";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";

// Define emoji mappings for your categories
const emojiMap = {
  "Fruits & Vegetables": "🍏🍌",
  "Bread & Pastries": "🥖🥐",
  "Milk & Cheese": "🥛🧀",
  "Meat & Fish": "🥩🐟",
  "Ingredients & Spices": "🌶️🧄",
  "Frozen & Convenience": "❄️🍱",
  "Grain Products": "🍞🌾",
  "Snacks & Sweets": "🍫🍬",
  "Beverages": "🥤☕",
  "Household": "🏠🧹",
  "Care & Health": "💊🩹",
  "Pet Supplies": "🐾🐶",
  "Home and Garden": "🏡🌷",
  "Uncategorised items": "❔❓",
};

const colorPalette = [
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
  "rgba(255, 99, 255, 0.5)",
  "rgba(50, 205, 50, 0.5)",
  "rgba(0, 128, 128, 0.5)",
  "rgba(255, 0, 255, 0.5)",
  "rgba(128, 0, 0, 0.5)",
  "rgba(128, 128, 0, 0.5)",
  "rgba(128, 0, 128, 0.5)",
  "rgba(0, 0, 128, 0.5)",
];

// Function to generate a donut chart using Chart.js
export function DonutChart({ canvasId, renderedReceiptId }) {
  const chartRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchReceiptData = async () => {
      const data = await fetchReceiptDetails(renderedReceiptId, "allUserReceipts");
    //   console.log("data in pie chart", data.processed_data);
      setData(data.processed_data);
    };

    fetchReceiptData();
  }, [renderedReceiptId]);

  useEffect(() => {
    if (data === null) {
      return;
    }

    const ctx = chartRef.current.getContext("2d");

    if (!ctx) {
      console.error("Canvas context not available.");
      return;
    }

    // Destroy existing Chart instance if it exists
    if (chartRef.current.chart) {
    //   console.log("Destroying existing Chart instance");
      chartRef.current.chart.destroy();
    }

    if (!Array.isArray(data.items)) {
      console.error("Data.items is not an array.");
      return;
    }

    const categoryTotals = {};
    data.items.forEach((recipe) => {
      const categories = recipe.category; // Assuming category is an array
      const totalPrice = recipe.price;
      categories.forEach((category) => {
        categoryTotals[category] = (categoryTotals[category] || 0) + totalPrice;
      });
    });

    const chartData = Object.keys(categoryTotals).map((category) => ({
      label: category,
      value: categoryTotals[category],
    }));

    const newChartData = chartData.map((item, index) => ({
      // label: emojiMap[item.label] || item.label, // Use emoji if available, otherwise use label
      label: `${item.label} (${emojiMap[item.label] || "?"})`, // Combine label and emoji
      value: item.value,
      backgroundColor: colorPalette[index % colorPalette.length], // Use modulo to cycle through the color palette
    }));

    const newChartInstance = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: newChartData.map((item) => item.label),
        datasets: [
          {
            data: newChartData.map((item) => item.value),
            backgroundColor: newChartData.map((item) => item.backgroundColor),
            borderColor: colorPalette.map((color) =>
              color.replace(", 0.5", ", 1")
            ), // Adjust border opacity to 1
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = newChartData[context.dataIndex].label;
                const value = context.dataset.data[context.dataIndex];
                return label + ": " + Math.round(value);
              },
            },
          },
        },
      },
    });

    // Save the new Chart instance to the ref
    chartRef.current.chart = newChartInstance;
  }, [data]);

  return (
    <Card className="max-w-[600px] m-auto">
      <CardHeader className="flex justify-center">
        <CardTitle className="text-center">
          Your expenses per category
        </CardTitle>
      </CardHeader>
      <CardContent>
        <canvas id={canvasId} ref={chartRef} />
      </CardContent>
    </Card>
  );
}
