import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../components/common/Loading.js";
import { auth, db } from "../firebase/config.js";
import InvoiceTable from "../components/track-foods/InvoiceTable.js"; // InvoiceTable component
import Authentication from "../components/auth/Authentication.js"; // Authentication component
import Dislike from "../components/track-foods/Dislike.js";
import SkeletonTable from "../components/track-foods/SkeletonTable.js";
import ReceiptTextDetail from "../components/track-foods/ReceiptTextDetail.js";
import { Button } from "../@/components/button";
import { ReceiptText } from "lucide-react";
import {
  maxIndulgence,
  calculateNonFoodItems,
  calculateEssentialItems,
  calculateVeganItems,
  calculateOrganicItems,
  calculateSugaryRating,
} from "../utils/dataStats";
import {
  updateFirebaseDocument,
  saveRecipeDataToFirebase,
} from "../utils/firestoreUtils";
import { fetchReceiptData } from "../utils/firestoreUtils";
import { useAuthState } from "react-firebase-hooks/auth";
import { calculateTotalCategories } from "../utils/dataStats";
import {
  processImage,
  extractText,
  sendItemsToBigquery,
  textLlmParse,
  imageLlmOpenai,
  nutritionistFeedback,
  threeRecipes
} from "../utils/api";
import NutAdvice from "../components/track-foods/NutAdvice";
import RecipeCarousel from "../components/track-foods/RecipeCarousel";

const dinnerOptions = {
  "dinner_options": [
      {
          "cuisine_type": "Korean",
          "difficulty_level": "Medium",
          "ingredients": [
              "Beef",
              "Soy sauce",
              "Brown sugar",
              "Garlic",
              "Ginger",
              "Sesame oil",
              "Green onions",
              "Sesame seeds"
          ],
          "instructions": [
              "1. Slice the beef into thin strips.",
              "2. In a bowl, combine the soy sauce, brown sugar, minced garlic, grated ginger, and sesame oil. Add the beef and toss to coat.",
              "3. Heat a large skillet or wok over high heat. Add the marinated beef and cook, stirring frequently, until the beef is browned and cooked through, about 10-15 minutes.",
              "4. Stir in the sliced green onions and sesame seeds.",
              "5. Serve the bulgogi beef over steamed rice."
          ],
          "name": "Korean Bulgogi Beef",
          "prep_time": "20 minutes",
          "serving_size": "4 servings",
          "total_time": "40 minutes"
      },
      {
          "cuisine_type": "Italian",
          "difficulty_level": "Easy",
          "ingredients": [
              "Pasta",
              "Canned diced tomatoes",
              "Heavy cream",
              "Parmesan cheese",
              "Garlic",
              "Basil",
              "Salt",
              "Pepper"
          ],
          "instructions": [
              "1. Bring a large pot of salted water to a boil. Cook the pasta according to package instructions until al dente.",
              "2. In a saucepan, combine the canned diced tomatoes, heavy cream, minced garlic, and a pinch of salt and pepper. Simmer for 10-15 minutes, stirring occasionally, until the sauce has thickened.",
              "3. Drain the cooked pasta and add it to the tomato cream sauce. Toss to coat the pasta evenly.",
              "4. Stir in grated Parmesan cheese and chopped fresh basil.",
              "5. Serve the creamy tomato pasta warm."
          ],
          "name": "Creamy Tomato Pasta",
          "prep_time": "15 minutes",
          "serving_size": "4 servings",
          "total_time": "30 minutes"
      },
      {
          "cuisine_type": "American",
          "difficulty_level": "Easy",
          "ingredients": [
              "Salmon fillets",
              "Lemon juice",
              "Dill",
              "Garlic",
              "Mayonnaise",
              "Salt",
              "Pepper"
          ],
          "instructions": [
              "1. Preheat the oven to 400°F (200°C).",
              "2. Place the salmon fillets on a baking sheet lined with parchment paper. Season with salt and pepper.",
              "3. Bake the salmon for 15-20 minutes, or until it flakes easily with a fork.",
              "4. In a small bowl, mix together the lemon juice, chopped dill, minced garlic, and mayonnaise. Season with salt and pepper.",
              "5. Serve the baked salmon warm, drizzled with the lemon dill sauce."
          ],
          "name": "Baked Salmon with Lemon Dill Sauce",
          "prep_time": "10 minutes",
          "serving_size": "2 servings",
          "total_time": "30 minutes"
      }
  ]
};

export function ResultTrackFoods() {
  const [loading, setLoading] = useState(true);
  const [recipieData, setRecipieData] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [user] = useAuthState(auth); // Check if the user is logged in

  const [showAuthentication, setShowAuthentication] = useState(false);
  const [showDelayedMessage, setShowDelayedMessage] = useState(true);
  const [isTextExtracted, setIsTextExtracted] = useState(false);
  const [isTextLLMParsed, setIsTextLLMParsed] = useState(false);

  const { key } = useParams();
  const chartRef = useRef(null);

  const [isExampleReceipt, setIsExampleReceipt] = useState(false);
  const [exampleReceiptKey, setExampleReceiptKey] = useState("");

  const [renderedReceiptId, setRenderedReceiptId] = useState(null); // Ensure renderedReceiptId is initialized and updated
  const [feedback, setFeedback] = useState(null);
  const [recipes, setRecipes] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDelayedMessage(false);
    }, 1000); // 30 seconds

    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    if (!user) {
      const timer = setTimeout(() => {
        setShowAuthentication(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [user]);

  const userSelectedAddress = (value) => {
    // print the value of userSelectedAddress
    setSelectedValue(value);
    if (value !== "") {
      setSelectedAddress(value);
      updateFirebaseDocument(
        key,
        "groceries_temp",
        "user_entered_address",
        value
      );

      if (user) {
        updateFirebaseDocument(
          key,
          "allUserReceipts",
          "user_entered_address",
          value
        );
      }
    }
  };

  useEffect(() => {
    const exampleReceipts = {
      "eu_user_bill.png": "Gge5f7NfNZc8X9RILCX1",
      "in_user_bill.png": "9lJ7HTDtONVh5nxtHUXx",
      "us_user_bill.png": "57F7hVEBRtxxPTkOoJsl",
    };

    const fetchData = async () => {
      if (key in exampleReceipts) {
        setIsExampleReceipt(true);
        const receiptKey = exampleReceipts[key];
        setExampleReceiptKey(receiptKey);

        if (receiptKey) {
          console.log("It is an example receipt", receiptKey);
          localStorage.setItem("exampleReceiptKey", receiptKey);
          setShowSkeleton(true);
          setLoading(true);
          try {
            const data = await fetchReceiptData(receiptKey);
            setRecipieData(data.processed_data);
            setShowSkeleton(false);
            setLoading(false);
          } catch (error) {
            console.error("Error fetching data:", error);
            setShowSkeleton(false);
            setLoading(false);
          }
        } else {
          console.log("Not an example receipt");
          setExampleReceiptKey("Unknown");
          setIsExampleReceipt(false);
        }
      } else {
        console.log("Not an example receipt");
        const getreceiptData = localStorage.getItem(localStorage.getItem(key));
        if (
          getreceiptData &&
          localStorage.getItem("imageUploaded") === "true"
        ) {
          setRecipieData(JSON.parse(getreceiptData));
          setShowSkeleton(false);
        } else {
          try {
            // first endpoint to process image
            const response1 = await processImage(localStorage.getItem(key));

            try {
              // second endpoint to extract text
              const response2 = await extractText(localStorage.getItem(key));
              
              setShowSkeleton(false);
              setIsTextExtracted(true);
              localStorage.setItem("imageUploaded", "true");
              // third endpoint to parse text
              // const response3 = await textLlmParse(localStorage.getItem(key));
              const response3 = await imageLlmOpenai(localStorage.getItem(key));
              localStorage.setItem(
                localStorage.getItem(key),
                JSON.stringify(response3.data)
              );
              setRecipieData(response3.data);
              setIsTextLLMParsed(true);
              setLoading(false);
            } catch (error) {
              console.error("Error fetching data from second endpoint:", error);
              setErrorOccurred(true);
              setLoading(false);
            }
          } catch (error) {
            console.error("Error fetching data from first endpoint:", error);
            setErrorOccurred(true);
            setShowSkeleton(false);
            setLoading(false);
          }
        }
      }
    };

    fetchData();
  }, [key, localStorage.getItem("imageUploaded")]);

  useEffect(() => {
    if (!loading && chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }
  }, [recipieData, loading]);

  useEffect(() => {
    if (isExampleReceipt) {
      setRenderedReceiptId(exampleReceiptKey);
    } else {
      setRenderedReceiptId(localStorage.getItem(key));
    }
  }, [key, isExampleReceipt, exampleReceiptKey]);

  // Update the useEffect for sending items to BigQuery:
  useEffect(() => {
    if (!loading && isTextLLMParsed && user) {
      saveRecipeDataToFirebase(localStorage.getItem(key));
      sendItemsToBigquery(localStorage.getItem(key))
        .then(() => {
          console.log("Successfully entered items in bigdata.");
        })
        .catch((error) => {
          console.error(
            "Error calling send-items-to-bigquery endpoint:",
            error
          );
        });
    }
  }, [loading, user]);

    // Show the nutritionist feedback
    useEffect(() => {
      if (!loading && isTextLLMParsed && user) {
  
        nutritionistFeedback(localStorage.getItem(key))
          .then((response) => {
            setFeedback(response.data);
            console.log("Successfully got nutritionist feedback.");

          })
          .catch((error) => {
            console.error(
              "Error calling nutritionist-feedback endpoint:",
              error
            );
          });
      }
    }, [loading, user]);

    // Show the recipes
    useEffect(() => {
      if (!loading && isTextLLMParsed && user) {
        threeRecipes(localStorage.getItem(key))
          .then((response) => {
            setRecipes(response.data);
            console.log("Successfully got three recipes.", response.data);
          })
          .catch((error) => {
            console.error("Error calling three-recipes endpoint:", error);
          });
      }
    }, [loading, user]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {showDelayedMessage && (
        <div>
          <p className="leading-7 [&:not(:first-child)]:mt-6">
            Your receipt was a bit longer than usual, we are still processing
            it. Please give us a few more seconds.
          </p>
        </div>
      )}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Loading
            textList={{
              "Uploading image...": 3000,
              "Scanning image...": 10000,
              "Processing receipt...": 30000,
              "Please wait a bit more...": 5000,
            }}
          />
        </div>
      ) : (
        <>
          {/* Render components based on data availability */}
          {feedback && <NutAdvice feedback={feedback["feedback"]} />}
          {recipes && <RecipeCarousel recipes={recipes.dinner_options} />}
          
          {recipieData && recipieData.items ? (
            <>
              <ReceiptTextDetail
                userSelectedAddress={userSelectedAddress}
                store_name={recipieData.store_name ?? "Unknown"}
                totalAmount={recipieData.total_amount ?? 0}
                shoppingDate={recipieData.datetime ?? "Unknown"}
                numberOfCategories={
                  calculateTotalCategories(recipieData.items) ?? 0
                }
                nonFoodItems={calculateNonFoodItems(recipieData.items) ?? 0}
                essentialItems={calculateEssentialItems(recipieData.items) ?? 0}
                numberOfItems={recipieData.items?.length ?? 0}
                averageIndulgence={maxIndulgence(recipieData.items)}
                numberOfVeganItems={calculateVeganItems(recipieData.items) ?? 0}
                numberOfOrganicItems={
                  calculateOrganicItems(recipieData.items) ?? 0
                }
                averageSugaryRating={
                  calculateSugaryRating(recipieData.items) ?? 0
                }
              />

              {/* add a component for nutritionist feedback */}
              
              {/* Other components */}
            </>
          ) : (
            <p>Error: Data not available</p>
          )}

          <div>
            {errorOccurred && (
              <p className="text-red-500 m-4 text-center">
                There was an error in processing yea receipt we are actively
                working on it and will improve our systems. Thank you for your
                feedback.
              </p>
            )}
          </div>

          {showSkeleton ? (
            <>
              <SkeletonTable />
            </>
          ) : (
            <>

              <InvoiceTable
                renderedReceiptId={renderedReceiptId}
                collectionName="groceries_temp"
              />

              {user && (
                <Button
                  onClick={() =>
                    (window.location.href = `/track-food/dashboard/${localStorage.getItem("userUid")}`)
                  }
                >
                  <ReceiptText className="mr-2 h-4 w-4" /> Past Receipts
                </Button>
              )}
            </>
          )
          }
          { !user && (
                <Authentication />
              )}
          {user && <Dislike param={key} />}
        </>
      )}
    </div>
  );
}