import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsNdConditions from "./pages/TermsNdConditions";

import Navbar from "./components/common/Navbar";

import { ResultTrackFoods } from "./pages/ResultTrackFoods";
import DetailReceipt from "./pages/DetailReceipt";
import Login from "./pages/Login";
import Loading from "./components/common/Loading";
import NotFound from "./components/common/NotFound";
import HomeNew from "./pages/HomeNew";
import Roadmap from "./pages/Roadmap";

export const routes = [
  {
    path: "/",
    element: <Navbar />,
    children: [
      {
        index: true,
        element: <HomeNew />,
      },
      {
        path: "/loading",
        element: (
          <Loading
            textList={{
              "Uploading image...": 3000,
              "Scanning image...": 10000,
              "Processing receipt...": 30000,
              "Please wait a bit more...": 5000,
            }}
          />
        ),
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsNdConditions />,
      },

      {
        path: "/roadmap",
        element: <Roadmap />,
      },
      {
        path: "/track-food/result/:key",
        element: <ResultTrackFoods />,
      },
      {
        path: "/track-food/dashboard/:userUid",
        element: <DetailReceipt />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];
