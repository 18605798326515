import {  CardHeader, CardDescription, CardTitle } from "../../@/components/card";
import { formatDate } from "../../utils/dataFormat";

const renderCardHeader = (description, value, itemChange) => (
    <CardHeader className="pb-2 text-left">
      <CardDescription>{description}</CardDescription>
      <CardTitle className="text-4xl">
        {value}
        {itemChange !== undefined && (
          <span className={`text-sm ${itemChange === null ? "text-gray-500" : itemChange >= 0 ? "text-green-500" : "text-red-500"}`}>
            {itemChange === null ? "(login)" : `(${itemChange >= 0 ? "+" : ""}${itemChange})`}
          </span>
        )}
      </CardTitle>
    </CardHeader>
  );
    

export const SingleReceiptMetrics = ({ numberOfItems, totalAmount, shoppingDate, numberOfFoodItems, numberOfLuxuryItems, protein, itemChange, currencySign, numberOfCategories, averageSugaryRating, numberOfVeganItems, numberOfOrganicItems }) => (
  <>
    <div className="grid grid-cols-2 grid-rows-6 gap-4">
      <div>{renderCardHeader("Number of Items", numberOfItems, itemChange)}</div>
      <div>{renderCardHeader(`Total Amount(${currencySign})`, totalAmount, itemChange)}</div>
      <div>{renderCardHeader("Number of Categories", numberOfCategories, itemChange)}</div>
      <div>{renderCardHeader("Date of Shopping", formatDate(shoppingDate))}</div>
      <div>{renderCardHeader("Number of Luxury Items", numberOfLuxuryItems, itemChange)}</div>
      <div>{renderCardHeader("Average Sugary Rating(0-5)", averageSugaryRating.toFixed(1), itemChange)}</div>
      <div>{renderCardHeader(`Amount spent on Protein (${currencySign})`, 'NA', itemChange)}</div>
      <div>{renderCardHeader("Number of Food Items", numberOfFoodItems, itemChange)}</div>
      <div>{renderCardHeader("Number of Vegan Items", numberOfVeganItems, itemChange)}</div>
      <div>{renderCardHeader("Number of Organic Items", numberOfOrganicItems, itemChange)}</div>
    </div>
  </>
);
