import React, { useEffect, useState } from "react";
import { auth, provider, db } from "../../firebase/config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { sendSignInLinkToEmail } from "firebase/auth";
import {
  collection,
  doc,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Input } from "../../@/components/input";
import { Icons } from "../../@/components/icons";
import { Button } from "../../@/components/button";
import { Mail } from "lucide-react";


const actionCodeSettings = {
  url: "http://localhost:3000/",
  handleCodeInApp: true,
};
const Authentication = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailToPassword, setEmailToPassword] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [dbData, setDbData] = useState([]);
  const [user, loading] = useAuthState(auth);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  useEffect(() => {
    const checkAuthStatus = () => {
      const userEmail = localStorage.getItem("email");
      if (userEmail) {
        // Check if the user is admin
          const adminEmails = require("../../config").adminEmails;
        // add null check for adminEmails
        if (adminEmails) {
          const isAdmin = adminEmails.includes(userEmail);
          localStorage.setItem("isAdmin", isAdmin);
        }
      }
    };

    checkAuthStatus();
  }, []);

  // Function to sync authentication with Firestore
  const syncUserDataWithFirestore = async (userData) => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const dbData = querySnapshot.docs.map((doc) => doc.data());

    const hasEmail = dbData.some((obj) => obj.email === userData.email);
    localStorage.setItem("userUid", userData.uid);
    // handleAddReceipt()
    if (!hasEmail) {
      try {
        const userRef = doc(db, "users", userData.uid);
        await setDoc(userRef, {
          created_time: new Date(),
          display_name: userData.displayName,
          email: userData.email,
          uid: userData.uid,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };


  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("userUid", data.user.uid);
        localStorage.setItem("isAdmin", false);

        // localStorage.setItem("uuid", data.user.uid);
        syncUserDataWithFirestore(data.user); // Call function to sync with Firestore
      })
      .catch((err) =>
        console.log("error happened while login with google", err)
      );
  };

  function isValidEmail(email) {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  }

  const [errorMessage, setErrorMessage] = useState("");

  const signInWithEmailPassword = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem("email", userCredential.user.email);
        syncUserDataWithFirestore(userCredential.user);
      })
      .catch((signInError) => {
        if (signInError.code === "auth/user-not-found") {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              localStorage.setItem("email", userCredential.user.email);
              syncUserDataWithFirestore(userCredential.user);
              localStorage.setItem("userUid", userCredential.user.uid);
            })
            .catch((createError) => {
              const errorCode = createError.code;
              const errorMessage = createError.message;
              setErrorMessage(
                `Error creating account: ${errorCode} - ${errorMessage}`
              );
            });
        } else if (signInError.code === "auth/invalid-credential") {
          setErrorMessage(
            "Invalid credentials. Please check your email and password."
          );
        } else {
          const errorCode = signInError.code;
          const errorMessage = signInError.message;
          setErrorMessage(`Error signing in: ${errorCode} - ${errorMessage}`);
        }

        // Send verification email
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            console.log("Verification email sent successfully");
            // Additional logic after sending the verification email
          })
          .catch((error) => {
            console.error("Error sending verification email:", error);
          });
      });
  };



  return (
    !user && (
      <>
        <div className="flex flex-col bg-[#D5DECB] min-w-[320px] m-auto p-8 rounded-2xl text-center space-y-4">
          <h1 className="text-2xl font-semibold tracking-tight">
            Get full results
          </h1>
          <p className="text-sm text-muted-foreground">
            Sign in or sign up to continue
          </p>

          <Button
            variant="outline"
            type="button"
            disabled={isLoading}
            onClick={signInWithGoogle}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.google className="mr-2 h-4 w-4" />
            )}
            &nbsp;&nbsp;Continue with Google
          </Button>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                Or continue with
              </span>
            </div>
          </div>

          {emailToPassword ? (
            <>
              <Input
                id="email"
                placeholder="name@example.com"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled={isLoading}
              />

              <Button
                type="button"
                disabled={isLoading}
                onClick={() =>
                  isValidEmail(email)
                    ? setEmailToPassword(false)
                    : setEmailValid(false)
                }
              >
                <Mail className="mr-2 h-4 w-4" /> Login with Email
              </Button>
            </>
          ) : (
            <>
              <p className="text-sm text-muted-foreground">
                Create a password below for your account
              </p>

              <Input
                type="password"
                placeholder="Your password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <Button variant="secondary" onClick={signInWithEmailPassword}>
                Sign Up
              </Button>
              {
                <p
                  className={`text-sm text-red-500 text-muted-foreground text-center my-3 ${password.length < 6 || (password.length > 1 && " opacity-0")}`}
                >
                  Password should be at least 6 characters
                </p>
              }
              {errorMessage && (
                <p className="text-red-500 text-muted-foreground text-center my-3">
                  {errorMessage}
                </p>
              )}
            </>
          )}
        </div>
      </>
    )
  );
};

export default Authentication;
