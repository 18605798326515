import React from 'react';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen" style={{ backgroundImage: '/onebananabg.png', backgroundSize: "cover", backgroundPosition: "center" }}>
      <h1 className="text-8xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-xl">The page you're looking for doesn't exist.</p>
    </div>
  );
};

export default NotFound;