import { format } from "date-fns";

export const formatDate = (shoppingDate) => {
    const date = new Date(shoppingDate);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
    });
  };

export const formatedDatetime = (date) => {
  try {
    return format(new Date(date), "EEEE, do MMM  HH:mm").replace(/(\d)(st|nd|rd|th)/, '$1');
  } catch (error) {
    console.error(error);
    return "NA";
  }
};