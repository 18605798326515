import React from "react";
import {Button} from "../../../@/components/button";
import { ArrowRight } from "lucide-react";


const CallToAction = () => (
  <div id="call-to-action" className="my-20 mt-40 p-4">
    <div className="my-4 text-center space-y-2">
      <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6">
        Ready to save money and eat healthier?
      </h2>
      <div className=" text-xl">
        Start by uploading your grocery bill!
      </div>
    </div>
    <div className="flex items-center justify-center gap-2 py-10">
      <Button href="#" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <ArrowRight className="mr-2 h-4 w-4" /> Get started now
      </Button>
    </div>
  </div>
);


export default CallToAction;
