import React, { useState, useEffect } from "react";
import FirestoreDocumentDetails from "../components/track-foods/FirestoreDocumentDetails";
import { getFirebaseImageUrl } from "../firebase/config";
import ReceiptDashboard from "../components/ReceiptDashboard";
import AddReceiptCard from "../components/track-foods/dashboard/AddReceiptCard";
import { fetchUserReceipts } from "../utils/firestoreUtils";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/config";
import TryFiveOutChat from "../components/track-foods/dashboard/TryFiveOutChat";

export default function DetailReceipt() {
  const [receiptDetails, setReceiptDetails] = useState(null);
  const userUid = localStorage.getItem("userUid");

  const [imageUrl, setImageUrl] = useState(null);
  const [renderedReceiptId, setRenderedReceiptId] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    fetchUserReceipts(userUid, "allUserReceipts");
  }, [userUid]);

  useEffect(() => {
    const fetchImageUrl = async () => {
      const url = await getFirebaseImageUrl(receiptDetails?.image_url);
      setImageUrl(url);
    };

    if (receiptDetails) {
      fetchImageUrl();
    }
  }, [userUid]);

  return (
    <div className="p-4 flex flex-col gap-4">
      {!receiptDetails && (
        <div className="flex justify-center items-center gap-8 flex-col md:flex-row">
          <AddReceiptCard />
          <TryFiveOutChat />
        </div>
      )}
      {user && (
        <FirestoreDocumentDetails
          setRenderedReceiptId={setRenderedReceiptId}
          user={user}
        />
      )}
      {renderedReceiptId && (
        <ReceiptDashboard renderedReceiptId={renderedReceiptId} />
      )}
    </div>
  );
}
