import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../@/components/card";

const NutAdvice = ({ feedback }) => {
  return (
    <div className="w-full max-w-4xl mx-auto">
      <h3 className="text-2xl font-semibold mb-4">Feedback</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {feedback.slice(0, 4).map((item, index) => (
          <Card key={index} className="shadow-md">
            <CardHeader>
              <CardTitle className="text-lg">Tip {index + 1}</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-700">{item}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default NutAdvice;