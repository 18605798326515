import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { auth,db } from "../firebase/config"; // Assuming you have a firebaseConfig.js file where you initialize your Firestore instance
import { useAuthState } from "react-firebase-hooks/auth";


export const fetchUserData = async (userUid) => {
  const usersCollectionRef = collection(db, "users");
  const q = query(usersCollectionRef, where("uid", "==", userUid));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const userDocSnapshot = querySnapshot.docs[0];
    return userDocSnapshot.data();
  }
  return null;
};

export const saveRecipeDataToFirebase = async (documentId) => {
  const user = auth.currentUser; // Directly access the current user
  if (user) {

    const uid = user.uid;
    const userRef = doc(db, "users", uid);
    try {
      const groceriesSnapshot = await getDoc(doc(db, "groceries_temp", documentId));
      const receiptsSnapshot = await getDoc(doc(db, "allUserReceipts", documentId));
      if (groceriesSnapshot.exists() && !receiptsSnapshot.exists()) {
        const recipeData = groceriesSnapshot.data();
        await setDoc(doc(db, "allUserReceipts", documentId), {
          ...recipeData,
          userRef: userRef,
        });
      } else if (!groceriesSnapshot.exists()) {
        console.error("Document does not exist in groceries_temp");
      } else if (receiptsSnapshot.exists()) {
        console.log("Document movement has already happened");
      }
    } catch (error) {
      console.error("Error saving recipe data:", error);
    }
  }
};

export const fetchReceiptData = async (documentId) => {
  const documentRef = doc(db, "groceries_temp", documentId);
  const docSnap = await getDoc(documentRef);
  return docSnap.exists() ? docSnap.data() : null;
};


export const fetchReceiptDetails = async (renderedReceiptId, collectionName) => {
  try {
    if (!renderedReceiptId || !collectionName) {
      console.error("Invalid renderedReceiptId or collectionName");
      return null;
    }
    const receiptRef = doc(db, collectionName, renderedReceiptId);
    const receiptDoc = await getDoc(receiptRef);
    if (receiptDoc.exists()) {
      const receiptData = receiptDoc.data();
      return receiptData;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching receipt details:", error);
    return null;
  }
};

export const updateFirebaseDocument = (
  key,
  collectionName,
  variableName,
  variableValue
) => {
  const userDocRef = doc(db, collectionName, localStorage.getItem(key));
  updateDoc(userDocRef, {
    [variableName]: variableValue,
  })
    .then(() => {
      console.log("New variable stored in the Firebase document.");
    })
    .catch((error) => {
      console.error("Error updating the Firebase document:", error);
    });
};

export const fetchUserReceipts = async (userUid) => {
  try {
    if (!userUid) {
      console.log("User UID not found in localStorage.");
      return;
    }

    const userRef = doc(db, "users", userUid);
    const q = query(
      collection(db, "allUserReceipts"),
      where("userRef", "==", userRef)
    );

    return await getDocs(q);
  } catch (error) {
    console.error("Error fetching user receipts:", error);
  }
};

export const filterReceiptsByDate = (
  selectedDate,
  filterType,
  user
) => {

  const dateKey =
    filterType === "uploadTime" ? "request_timestamp" : "processed_data.datetime";

  if (filterType === "uploadTime") {
    return FilterDataByDate(
      selectedDate,
      dateKey,
      "request_timestamp",
      user
    );
  } else {
    console.log(
      "filterType is not 'uploadTime' , so filter based on shopping date"
    );
    return FilterDataByDate(
      selectedDate,
      dateKey,
      "processed_data.datetime",
      user
    );
  }
};

// Function to get the date value based on the nested key
const getDateValue = (data, key) => {
  const keys = key.split(".");
  let value = data;
  for (const k of keys) {
    value = value?.[k];
  }
  return value;
};


const FilterDataByDate = async (
  selectedDate,
  dateKey,
  timestampKey,
  user
) => {
  try {
    const userRef = doc(db, "users", user.uid);
    const q = query(
      collection(db, "allUserReceipts"),
      where("userRef", "==", userRef)
    );
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs;


    return documents
      .filter((doc) => {
        const dateValue = getDateValue(doc.data(), dateKey);
        return dateValue && dateValue.slice(0, 10) === selectedDate;
      })
      .map((doc) => ({ id: doc.id, data: doc.data() }))
      .sort((a, b) => new Date(a.data[timestampKey]) - new Date(b.data[timestampKey]));
  } catch (error) {
    console.error("Error filtering documents by date:", error);
    return [];
  }
};

export const fetchTotalReceiptsCount = (db, userUid, setTotalReceiptsCount, setTotalAmountSpent) => {
  const refreshTotalReceiptsCount = () => {
    try {
      const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
      const userRef = doc(db, "users", userUid);
      const q = query(
        allUserReceiptsCollectionRef,
        where("userRef", "==", userRef)
      );
      getDocs(q).then((querySnapshot) => {
        const documents = querySnapshot.docs;

        // console.log("looking for userRef", userRef.id);

        let receiptsCount = 0;
        let amountSpent = 0;

        documents.forEach((doc) => {
          const requestData = doc.data();
          receiptsCount += 1;
          amountSpent += requestData?.processed_data?.total_amount || 0;
        });

        // console.log("receiptsCount", receiptsCount);
        // console.log("amountSpent", amountSpent);

        setTotalReceiptsCount(receiptsCount);
        setTotalAmountSpent(amountSpent);
      }).catch((error) => {
        console.error("Error fetching total receipts count:", error);
      });
    } catch (error) {
      console.error("Error fetching total receipts count:", error);
    }
  };

  refreshTotalReceiptsCount();

  window.addEventListener('beforeunload', refreshTotalReceiptsCount);

  return () => {
    window.removeEventListener('beforeunload', refreshTotalReceiptsCount);
  };
};

// function to calculate aggregation of expense on monthly basis

export const fetchMonthlyExpenseAggregation = async (db, userUid, setMonthlyExpenseAggregation) => {
  try {
    const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
    const userRef = doc(db, "users", userUid);
    const q = query(
      allUserReceiptsCollectionRef,
      where("userRef", "==", userRef)
    );
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs;

    // aggregate the expense on monthly basis
    const monthlyExpenseAggregation = {};

    documents.forEach((doc) => {  
      const requestData = doc.data();
      const date = requestData?.processed_data?.datetime?? "Unknown";
      const month = date.slice(0, 7);
      monthlyExpenseAggregation[month] = (monthlyExpenseAggregation[month] || 0) + (requestData?.processed_data?.total_amount || 0);
    });

    // print the monthly expense aggregation
    setMonthlyExpenseAggregation(monthlyExpenseAggregation);
  } catch (error) {
    console.error("Error fetching monthly expense aggregation:", error);
  }
};
