import React, { useState } from "react";
import MoreModal from "./MoreModal";
import { db } from "../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

export default function Dislike({param}) {
  const [dislike, setDislike] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackFromInput, setFeedbackFromInput] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackSentThanks, setFeedbackSentThanks] = useState(false);

  const syncUserFeedbackWithFirestore = async (value) => {
    setFeedback(value);
    const receipt_uid = localStorage.getItem(param);
    try {
      const userRef = doc(db, "allUserReceipts", receipt_uid);
      await updateDoc(userRef, {
        feedback:{
          timestamp: new Date(),
          thumbs_down_reason: value,
          custom_reason_text: feedbackFromInput
        }
      });
      setDislike(false);
      setFeedbackSent(true);
      setFeedbackSentThanks(true);
      setTimeout(() => {
        setFeedbackSentThanks(false);
      }, 2000);
    } catch (e) {
      console.error("Error adding feedback: ", e);
    }
  };

  return (
    <div className="w-screen">
      {!feedbackSent && (
        <div className="w-full text-center">
          <span
            className=""
            data-state="closed"
            onClick={() => setDislike(!dislike)}
          >
            <button className="p-1 rounded-md text-token-text-tertiary hover:text-token-text-primary ">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-md"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          </span>
        </div>
      )}
      {dislike && (
        <div className="relative mt-2 flex  flex-col gap-3 rounded-lg border border-token-border-light p-4 w-11/12 max-w-3xl m-auto mb-8">
          <button
            className="absolute right-4 top-4 text-sm font-bold"
            onClick={() => setDislike(!dislike)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-md text-token-text-secondary hover:text-token-text-primary"
            >
              <path
                d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
          <div className="text-sm text-left">Tell us more:</div>
          <div className="flex flex-wrap gap-3">
            {feedbackArray.map((value, idx) => (
              <button
                key={idx}
                onClick={() => syncUserFeedbackWithFirestore(value)}
                className={`rounded-lg border border-token-border-light px-3 py-1 text-sm text-token-text-secondary ${feedback == value ? "hover:bg-black text-white" : "hover:bg-slate-100"} ${feedback == value && "bg-black text-white"}`}
              >
                {value}
              </button>
            ))}

            <MoreModal
              feedback={feedback}
              setFeedback={setFeedback}
              feedbackFromInput={feedbackFromInput}
              setFeedbackFromInput={setFeedbackFromInput}
              handleSubmit={syncUserFeedbackWithFirestore}
            />
          </div>
        </div>
      )}
      {feedbackSentThanks && (
        <div className="relative mt-2  rounded-lg border  p-4 text-center mb-8 w-fit m-auto px-5 py-2">
          <p>Thanks for the Feedback!</p>
        </div>
      )}
    </div>
  );
}

const feedbackArray = [
  `Don't like the style`,
  `Not factually correct`,
  `Didn't fully follow instructions`,
  `Refused when it shouldn't have`,
  `Being lazy`,
];
