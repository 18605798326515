import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/config";
import { useNavigate, useLocation } from "react-router-dom";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  Card,
} from "../../@/components/card";
import { Label } from "../../@/components/label";
import { Input } from "../../@/components/input";
import { Link } from "react-router-dom";
import { Button } from "../../@/components/button";
import {
  signInWithGoogle,
  signInWithEmailPassword,
  sendPassResetEmail,
} from "../../firebase/authUtils";

export const AuthShadcnNew = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [user] = useAuthState(auth);

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  // const [email, setEmail] = useState('');

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const [infoMsg, setInfoMsg] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);



  useEffect(() => {
    if (user) {
      // user is already signed in
      navigate("/login");
    } else {
      // user is not signed in but the link is valid
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem("email");
        if (!email) {
          email = window.prompt("Hey, please confirm your email one last time since you're in incognito mode.");
          localStorage.setItem("email", email);
        }

        // after that we will complete the login process
        setInitialLoading(true);
        signInWithEmailLink(
          auth,
          email,
          window.location.href
        )
          .then((result) => {
            // we can get the user from result.user but no need in this case
            setInitialLoading(false);
            setInitialError("");
            setEmailVerified(true);
            navigate("/login");
          })
          .catch((err) => {
            // print that error
            console.log(err);
            setInitialLoading(false);
            setInitialError(err.message);
            navigate("/login");
          });
      } else {
        console.log("enter email or sign in with google");
      }
    }
  }, [user, search, navigate]);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    // check if the code in running in production or dev 
    let url;
    if (process.env.NODE_ENV === "production") {
      url = "https://www.5outapp.com/login";
    } else {
      url = "http://localhost:3000/login";
    }

    
    sendSignInLinkToEmail(auth, email, {
      // this is the URL that we will redirect back to after clicking on the link in mailbox
      url: url,
      handleCodeInApp: true,
    })
      .then(() => {
        localStorage.setItem("email", email);
        setLoginLoading(false);
        setLoginError("");
        setInfoMsg("We have sent you an email with a link to sign in");
      })
      .catch((err) => {
        setLoginLoading(false);
        setLoginError(err.message);
      });
  };

  useEffect(() => {
    if (emailVerified) {
      localStorage.setItem("email", email);
      localStorage.setItem("userUid", user.uid);
    }
  }, [emailVerified, email, user]);

  return (
    <>
    {emailVerified ? (
          <div className="text-center">
            <p>Thanks for verifying the email</p>
            <Button onClick={() => navigate(`/track-food/dashboard/${user.uid}`)}>Go to Dashboard</Button>
          </div>
        ) : (
          
      <div className="p-4">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account
          </CardDescription>
        </CardHeader>
        {initialLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {initialError !== "" ? (
              <div className="error-msg">{initialError}</div>
            ) : (
              <>
                {/* We are checking user because for a split second, we will not have user */}
                {user ? (
                  // so instead of seeing form, I am using this please wait msg
                  <div>Please wait...
                  <Button onClick={() => navigate(`/track-food/dashboard/${user.uid}`)}>Go to Dashboard</Button>
                  </div>
                ) : (
                  // for a split second we will see this form
                  <div>
                      <CardContent>
                        <div className="grid gap-8">
                          <form
                            className="form-group custom-form"
                            onSubmit={handleLogin}
                          >
                            <div className="grid gap-4">
                              <Label htmlFor="email">Email</Label>
                              <Input
                                type={"email"}
                                required
                                placeholder="joe.musk@gmail.com"
                                className="form-control"
                                value={email || ""}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>

                            <Button type="submit" className="w-full mt-4">
                              {loginLoading ? (
                                <span>Logging you in</span>
                              ) : (
                                <span>Login</span>
                              )}
                            </Button>

                            {/* show login error msg */}
                            {loginError !== "" && (
                              <div className="error-msg">{loginError}</div>
                            )}

                            {/* show info msg */}
                            {infoMsg !== "" && (
                              <div className="info-msg">{infoMsg}</div>
                            )}
                          </form>
                          
                        <div class="relative">
                          <div class="absolute inset-0 flex items-center">
                            {user ? null : <span class="w-full border-t"></span>}
                          </div>
                          <div class="relative flex justify-center text-xs uppercase">
                            <span class="bg-background px-2 text-muted-foreground">Or continue with</span>
                          </div>
                        </div>
                        
                         <Button
                            className="w-full"
                            variant="outline"
                            onClick={signInWithGoogle}
                          >
                            Login with Google
                          </Button>
                        </div>
                      </CardContent>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Card>
      </div>
      )}
    </>
  );
};
