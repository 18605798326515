
import { AuthShadcnNew } from "../components/auth/AuthShadcnNew"
// add logout button from components/Logout.js
import LogOut from "../components/auth/LogOut";
const Login = () => {
  return (
    <>
    <AuthShadcnNew />
    
    <LogOut />
    </>

  );
};

export default Login;

