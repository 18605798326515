import React from "react";
import InvoiceTable from "./InvoiceTable";
import { Skeleton } from "@mui/material";

export default function SkeletonTable() {
  const skeletonData = [
    {
      item_name: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={150}
        />
      ),
      price: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={25}
          className="ml-auto"
        />
      ),
    },
    {
      item_name: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={150}
        />
      ),
      price: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={25}
          className="ml-auto"
        />
      ),
    },
    {
      item_name: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={150}
        />
      ),
      price: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={25}
          className="ml-auto"
        />
      ),
    },
    {
      item_name: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={150}
        />
      ),
      price: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={25}
          className="ml-auto"
        />
      ),
    },
    {
      item_name: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={150}
        />
      ),
      price: (
        <Skeleton
          animation={"wave"}
          variant="text"
          sx={{ fontSize: "3rem" }}
          width={25}
          className="ml-auto"
        />
      ),
    },
  ];
  return <>{<InvoiceTable skeletonData={skeletonData} />}</>;
}
