// ReceiptDashboard.js
import React, { useState, useEffect, useRef } from "react";
import SupermarketCard from "./track-foods/SupermarketCard";
import InvoiceTable from "./track-foods/InvoiceTable";
import { DonutChart } from "./track-foods/DonutChart";
import { fetchReceiptDetails } from "../utils/firestoreUtils";

const ReceiptDashboard = ({
  renderedReceiptId,
}) => {
  const [receiptDetails, setReceiptDetails] = useState(null);



  useEffect(() => {
    const fetchReceiptData = async () => {
      const data = await fetchReceiptDetails(renderedReceiptId, "allUserReceipts");
      setReceiptDetails(data);
    };

    fetchReceiptData();
  }, [renderedReceiptId]);

  if (!receiptDetails) {
    return <div>Loading...</div>; // Render a loading indicator while data is being fetched
  }

  return (

    <div className="p-4 flex flex-col gap-4 justify-center items-center">
      {receiptDetails && (
        <>
          <SupermarketCard
            renderedReceiptId={renderedReceiptId}
          />
          <div className="my-8"></div>
          <DonutChart
            canvasId="myChart"
            renderedReceiptId={renderedReceiptId}
          />
        </>
      )}

      {receiptDetails && (
        <InvoiceTable
          renderedReceiptId={renderedReceiptId}
          collectionName="allUserReceipts"
          />

      )}


    </div>
  );
};

export default ReceiptDashboard;
