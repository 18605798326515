import React from "react";
import { Card, CardTitle, CardDescription } from "../../@/components/card";
import { ShoppingBasket, FileUp, ShoppingCart } from "lucide-react";
import { formatedDatetime } from "../../utils/dataFormat";


const ReceiptCard = ({ receipt }) => {
  // get if it is admin from local storage
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  return (
    <Card className="p-4">
      <CardTitle>{receipt.data?.processed_data?.store_name}</CardTitle>
      <CardDescription className="mt-2">
        {isAdmin && (
          <p className="text-gray-500">
            uid:{" "}
            <span className="text-black font-[500]">{receipt.id}</span>
          </p>
        )}
        <p className="text-gray-500">
          Total :{" "}
          <span className="text-black font-[500]">
            {receipt.data?.processed_data?.total_amount}€
          </span>
        </p>
        <p className="text-gray-500">
          <ShoppingBasket className="inline-block h-5 w-5 text-gray-500" />{" "}
          <span className="text-black font-[500]">
            {receipt.data?.processed_data?.items?.length}
          </span>
        </p>
        <p className="text-gray-500">
          <FileUp className="inline-block h-5 w-5 text-gray-500" />{" "}
          <span className="text-black font-[500]">
            {formatedDatetime(receipt.data?.request_timestamp)}
          </span>
        </p>
        <p className="text-gray-500">
          <ShoppingCart className="inline-block h-5 w-5 text-gray-500" />{" "}
          <span className="text-black font-[500]">
            {formatedDatetime(receipt.data?.processed_data?.datetime?? "Unknown")}
          </span>
        </p>
      </CardDescription>
    </Card>
  );
};

export default ReceiptCard;