import React from "react";

const HowItWorks = () => (
  <div id="how-it-works" className="w-full max-w-5xl m-auto my-20 p-4">
    <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6 text-center">
      How it works
    </h2>
    <div className="text-lg py-4">
      <p className="mt-4">
        Just upload the grocery recipet which you have recived from the store.
        It will analyze for you and provide some key statistics on it.
      </p>

      <p className="mt-4">
        If you sign up, you can keep track of all the receipts you have
        uploaded so far.
      </p>

      <p className="mt-4">
        Cool new feature will be coming out to suggest you to eat better while
        not spend too much.
      </p>
    </div>

    {/* <div className="py-4">
      <code>
        https://5outapp/get?url=
        <span className="px-1 py-0.5 border border-gray-300 font-semibold rounded-md bg-gray-100">
          yourwebsite.com/blogs/article-1
        </span>
      </code>
    </div> */}
    
    {/* <div className="w-full">
      <PreformattedCode />
    </div> */}

  </div>
);

const PreformattedCode = () => (
  <pre
    style={{
      color: "black",
      background: "#f5f2f0",
      textShadow: "0 1px white",
      fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
      fontSize: "0.875em",
      textAlign: "left",
      whiteSpace: "pre",
      wordSpacing: "normal",
      wordBreak: "normal",
      wordWrap: "normal",
      lineHeight: "1.25em",
      tabSize: 4,
      hyphens: "none",
      padding: "1em",
      margin: ".5em 0",
      overflow: "auto",
    }}
  >
    <code
      style={{ lineHeight: "inherit", fontSize: "inherit", whiteSpace: "pre" }}
    >
      &lt;!-- Put in your &lt;head&gt; tag --&gt;{"\n"}
      &lt;meta property="og:image"
      content="https://image.social/get?url=yourwebsite.com/blogs/article-1"
      /&gt;
    </code>
  </pre>
);

export default HowItWorks;
