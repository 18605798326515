// Function to get approximate geolocation from the user's IP address
const getApproximateLocationFromIP = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    if (response.ok) {
      const data = await response.json();
      const { city, region, country, latitude, longitude } = data;
      return { city, region, country, latitude, longitude };
    } else {
      throw new Error("Failed to fetch geolocation data from IP address.");
    }
  } catch (error) {
    console.error("Error getting approximate geolocation:", error);
    return null;
  }
};
// Function to get the user's current location using the Geolocation API
const getCurrentPosition = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

// Function to get the user's current location using the Geolocation API
const getLocationFromBrowser = async () => {
  if (navigator.geolocation && navigator.permissions) {
    try {
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      if (permission.state === 'granted') {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        if (latitude && longitude) {
          return { latitude, longitude };
        } else {
          console.error("Failed to get user's location from browser.");
        }
      } else {
        // console.log("Geolocation permission not granted.");
        // i will try to get the location from the IP
        const location = await getApproximateLocationFromIP();

        if (location) {
          return { latitude: location.latitude, longitude: location.longitude };
        } else {
          console.error("Failed to get approximate location.");
        }
      }
    } catch (error) {
      console.error("Error getting user's location from browser:", error);
    }
  }
  return { latitude: 0, longitude: 0 }; // Default location
};


export { getLocationFromBrowser };
