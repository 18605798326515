import config from "../config";
import axios from "axios";

export const API_ENDPOINTS = {
  uploadImage: `${config.apiEndpoints.uploadImage}`,
  processImage: `${config.apiEndpoints.processImage}`,
  extractText: `${config.apiEndpoints.extractText}`,
  textLlmParse: `${config.apiEndpoints.textLlmParse}`,
  imageLlmOpenai: `${config.apiEndpoints.imageLlmOpenai}`,
  sendItemsToBigquery: `${config.apiEndpoints.sendItemsToBigquery}`,
  nutritionistFeedback: `${config.apiEndpoints.nutritionistFeedback}`,
};

export const uploadImage = async (key, image) => {
  try {
    const formData = new FormData();
    formData.append("image", image);
    const response = await axios.post(API_ENDPOINTS.uploadImage, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: { key },
    });
    console.log(`Upload Image API call successful`);
    return response;
  } catch (error) {
    console.error(
      `Error in Upload Image API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};

export const processImage = async (key) => {
  // note: this api is only needed when we are usingg use_preprocessed_image=True in the extract text api call
  // aws textract should be able to handle processing of images directly
  try {
    const response = await axios.post(API_ENDPOINTS.processImage, null, {
      params: { key },
    });
    console.log(
      `Process Image API call successful. Status: ${response.status}`
    );
    return response;
  } catch (error) {
    console.error(
      `Error in Process Image API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};

export const extractText = async (key) => {
  try {
    const response = await axios.post(API_ENDPOINTS.extractText, null, {
      params: {
        key,
        method: "aws_textract",
        use_preprocessed_image: "False",
      },
    });
    console.log(`Extract Text API call successful. Status: ${response.status}`);
    return response;
  } catch (error) {
    console.error(
      `Error in Extract Text API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};

export const textLlmParse = async (key) => {
  try {
    const response = await axios.post(API_ENDPOINTS.textLlmParse, null, {
      params: {
        key,
        max_tokens: "3000",
        system_message_location:
          "gs://mandi-3e35b.appspot.com/system_messages/system_message1.txt",
        model_name: "gpt-3.5-turbo-0125",
      },
    });
    console.log(
      `Text LLM Parse API call successful. Status: ${response.status}`
    );
    return response;
  } catch (error) {
    console.error(
      `Error in Text LLM Parse API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};


export const imageLlmOpenai = async (key) => {
  try {
    const response = await axios.post(API_ENDPOINTS.imageLlmOpenai, null, {
      params: {
        key
      },
    });
    return response;
  } catch (error) {
    console.error("Error in Image LLM OpenAI API call:", error);
    console.error("Error details:", error.response?.data);
    throw error;
  }
};


export const nutritionistFeedback = async (key) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.nutritionistFeedback,
      null,
      {
        params: {
          key,
          max_tokens: "3000",
          system_message_location:
            "gs://mandi-3e35b.appspot.com/system_messages/system_message_nutrition_counseling_1.txt",
        },
      }
    );
    console.log(
      `Nutritionist Feedback API call successful. Status: ${response.status}`
    );
    return response;
  } catch (error) {
    console.error(
      `Error in Nutritionist Feedback API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};

export const threeRecipes = async (key) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.nutritionistFeedback,
      null,
      {
        params: {
          key,
          max_tokens: "3000",
          system_message_location:
            "gs://mandi-3e35b.appspot.com/system_messages/system_message_recipes_1.txt",
        },
      }
    );
    console.log(
      `Nutritionist Feedback API call successful. Status: ${response.status}`
    );
    return response;
  } catch (error) {
    console.error(
      `Error in Nutritionist Feedback API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};

export const sendItemsToBigquery = async (key) => {
  try {
    const response = await axios.post(API_ENDPOINTS.sendItemsToBigquery, null, {
      params: { key },
    });
    console.log(
      `Send Items to BigQuery API call successful. Status: ${response.status}`
    );
    return response;
  } catch (error) {
    console.error(
      `Error in Send Items to BigQuery API call. Status: ${error.response?.status}`,
      error
    );
    throw error;
  }
};