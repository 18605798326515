export const  calculateAverageIndulgence = (items) => {
  if (items.length > 0) {
    const totalIndulgence = items.reduce(
      (sum, item) => sum + item.indulgence_rating,
      0
    );
    const averageIndulgence = totalIndulgence / items.length;
    // console.log("Average indulgence:", averageIndulgence);
    return averageIndulgence;
  }
};

export const maxIndulgence = (items) => {
  if (items.length > 0) {
    const maxIndulgence = Math.max(...items.map((item) => item.indulgence_rating));
    // console.log("Max indulgence:", maxIndulgence);
    return maxIndulgence;
  }
};

// to calculate total numner of categories
export const calculateTotalCategories = (items) => {
  const uniqueCategories = new Set();
  items.forEach((item) => {
    item.category?.forEach((category) => {
      uniqueCategories.add(category);
    });
  });
  return uniqueCategories.size;
};


export const setUniqueReceiptData = async (documentDetails, setDatesOfReceiptsUploaded, setDatesOfReceipts) => {
  if (!documentDetails.empty) {
    try {
      const data = documentDetails.docs.map((doc) => {
        const requestData = doc.data();
        const receiptUid = doc.ref.id;
        const timestamp = requestData.request_timestamp;
        const receiptDatetime = requestData?.processed_data?.datetime?? "Unknown";

        return {
          receiptUid,
          timestamp,
          receiptDatetime,
          requestData,
        };
      });

      const uniqueReceiptDatesArray = Array.from(new Set(data.map((item) => item.receiptDatetime)));
      const uniqueDatesArray = Array.from(new Set(data.map((item) => item.timestamp)));

      setDatesOfReceiptsUploaded(uniqueDatesArray);
      setDatesOfReceipts(uniqueReceiptDatesArray);
    } catch (error) {
      console.error("Error fetching receipt data:", error);
    }
  }
};


  // Function to calculate non food items count
  export const calculateNonFoodItems = (items) => {
    return items.filter((item) => !item.edible).length;
  };

  // Function to calculate essential items count
  export const calculateEssentialItems = (items) => {
    return items.filter((item) => item.luxury_level <= 2).length;
  };

  // calculate the number of vegan items, the is a bool called is_vegan. So count the number where is_vegan is true
  export const calculateVeganItems = (items) => {
    return items.filter((item) => item.is_vegan).length;
  };

  // calculate the number of organic items, the is a bool called is_organic. So count the number where is_organic is true
  export const calculateOrganicItems = (items) => {
    return items.filter((item) => item.is_organic).length;
  };

// calculate overall sugary rating , there is a filed called  sugary_rating. so take average of it 
export const calculateSugaryRating = (items) => {
  return items.reduce((sum, item) => sum + item.sugary_rating, 0) / items.length;
};

// calculate % increase in expense compared to last month, input is object with month as key and amount as value
export const calculatePercentageIncrease = (monthlyExpenseAggregation) => {
  // current month from today
  const currentMonth = new Date().toISOString().slice(0, 7);
  const currentMonthAmount = monthlyExpenseAggregation[currentMonth];
  const lastMonthAmount = monthlyExpenseAggregation[currentMonth - 1];
  const percentageIncrease = ((currentMonthAmount - lastMonthAmount) / lastMonthAmount) * 100;

  return percentageIncrease;
};
