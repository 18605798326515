import React from "react";
import { AspectRatio } from "../../../@/components/aspect-ratio";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../@/components/avatar";
import { Button } from "../../../@/components/button";


function ReceiptExample({ receiptType, avatarSrc, aspectRatioSrc, href }) {
  return (
    <div className="mx-auto text-center ">
      <h3 className="scroll-m-20 text-xl tracking-tight text-center">
        Try with a receipt from {receiptType}
      </h3>
      <div className="my-8 w-[240px] mx-auto relative">
        <AspectRatio ratio={3 / 4} className="mx-auto relative">
          <img
            src={`/examples/${aspectRatioSrc}`}
            alt="Supermarket"
            className="rounded-md object-cover w-full h-full"
          />
          <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
            <Avatar>
              <AvatarImage src={`/examples/${avatarSrc}`} />
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
          </div>
        </AspectRatio>
        <Button variant="secondary" className="mt-4 rounded-full" onClick={() => window.location.href=href}>
          Try
        </Button>
      </div>
    </div>
  );
}

export function ExampleSection() {
  return (
    <div id="demo">
      <h1 className="text-2xl font-semibold sm:text-4xl text-center my-4 ">
        Don't have a grocery bill handy?
      </h1>
      <ReceiptExample
        receiptType="Europe"
        avatarSrc="eu_user_avatar.png"
        aspectRatioSrc="eu_user_bill.png"
        href="/track-food/result/eu_user_bill.png"
      />
      <ReceiptExample
        receiptType="US"
        avatarSrc="us_user_avatar.png"
        aspectRatioSrc="us_user_bill.png"
        href="/track-food/result/us_user_bill.png"
      />
      <ReceiptExample
        receiptType="India"
        avatarSrc="in_user_avatar.png"
        aspectRatioSrc="in_user_bill.png"
        href="/track-food/result/in_user_bill.png"
      />
    </div>
  );
}