import React, { useState, useEffect } from "react";
import { ScrollArea, ScrollBar } from "../../@/components/scroll-area";
import ReceiptCard from "./ReceiptCard";
import { renderSelect, getFilteredReceipts } from "./FilterSelect";

const RenderReceipts = ({
  selectedDate,
  setRenderedReceiptId,
  user
}) => {

  const handleReceiptClick = (receiptId) => {
    if (localStorage.getItem("userUid")) {
      setRenderedReceiptId(receiptId);
      // console.log("Rendering receipt with ID:", receiptId);
    } else {
      console.log("User is not logged in. Cannot render receipt details.");
    }
  };

  const [filteredReceipts, setFilteredReceipts] = useState([]);

  const [currentFilter, setCurrentFilter] = useState("uploadTime");




  useEffect(() => {
    // console.log("currentFilter in useEffect:", currentFilter);
    getFilteredReceipts(currentFilter, selectedDate, user)
      .then((newFilteredReceipts) => {
        setFilteredReceipts(newFilteredReceipts);
      })
      .catch((error) => {
        console.error("Error fetching filtered receipts:", error);
      });
  }, [currentFilter, selectedDate, user]);


  return (
    <ul className="max-w-sm mx-auto">
      {/* add a drop down with options to fileter by upload date or shopping date */}
      {renderSelect(currentFilter, setCurrentFilter)}

      {filteredReceipts.length > 0 ? (
        <ScrollArea >
          <div className="flex w-max space-x-4 p-4">
            {filteredReceipts.map((receipt, idx) => (
              <li
                className="cursor-pointer m-4 bg-gray-100 "
                key={idx}
                onClick={() => {
                  handleReceiptClick(receipt.id);
                }}
              >
                <ReceiptCard receipt={receipt}  />
              </li>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      ) : (
        <p className="text-center text-gray-500 max-w-sm mx-auto">
          No data found for this date. Please check previous dates.
          Filter State: {currentFilter}
        </p>
      )}
    </ul>
  );
};

export default RenderReceipts;
