import React, { useEffect, useState,  } from "react";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
  CardFooter,
} from "../../../@/components/card";
import { Progress } from "../../../@/components/progress";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db,  } from "../../../firebase/config";
import "chartjs-adapter-date-fns";
import { useBarplot } from "./Barplot";
import {
  fetchTotalReceiptsCount,
  fetchMonthlyExpenseAggregation,
  } from "../../../utils/firestoreUtils";
import { calculatePercentageIncrease } from "../../../utils/dataStats";
import { DuplicateRemover} from "./DuplicateRemover";



function Dashboard() {
  const [totalReceiptsCount, setTotalReceiptsCount] = useState(0);
  const [totalAmountSpent, setTotalAmountSpent] = useState(0);
  const userUid = localStorage.getItem("userUid");
  // const unsubscribe = useRef(null);
  const [dataForPlotting, setDataForPlotting] = useState([]);
  const [monthlyExpenseAggregation, setMonthlyExpenseAggregation] = useState(
    {}
  );
  const [percentageIncrease, setPercentageIncrease] = useState(0);
  // const [user] = useAuthState(auth); // Check if the user is logged in

  useEffect(() => {
    const fetchDataForPlotting = async () => {
      try {
        const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
        const userRef = doc(db, "users", userUid);
        const q = query(
          allUserReceiptsCollectionRef,
          where("userRef", "==", userRef)
        );
        const querySnapshot = await getDocs(q);
        const documents = querySnapshot.docs;
        

        const newDataForPlotting = documents.map((doc) => {
          const requestData = doc.data();
          return {
            date: requestData?.processed_data?.datetime ?? "Unknown",
            amount: requestData?.processed_data?.total_amount ?? 0,
            supermarket: requestData?.processed_data?.store_name ?? "Unknown",
          };
        });

        setDataForPlotting(newDataForPlotting);

      } catch (error) {
        console.error("Error fetching data for plotting:", error);
      }
    };

    fetchDataForPlotting();
  }, [userUid]);

  useEffect(() => {
    fetchTotalReceiptsCount(
      db,
      userUid,
      setTotalReceiptsCount,
      setTotalAmountSpent
    );
    fetchMonthlyExpenseAggregation(db, userUid, setMonthlyExpenseAggregation);
    setPercentageIncrease(
      calculatePercentageIncrease(monthlyExpenseAggregation)
    );
  }, []);

  // // print the monthly expense aggregation

  useEffect(() => {
    const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
    const userRef = doc(db, "users", userUid);
    const q = query(
      allUserReceiptsCollectionRef,
      where("userRef", "==", userRef)
    );



  }, [userUid]);

  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 28); // 4 weeks before today
  const endDate = new Date(today);

  useBarplot(dataForPlotting, startDate, endDate);

  return (
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <Card>
          <CardHeader className="pb-2">
            <CardDescription>Total </CardDescription>
            <CardTitle className="text-4xl">{totalReceiptsCount}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-xs text-muted-foreground">Receipts</div>
          </CardContent>
          <CardFooter>
            <Progress aria-label="25% increase" value={25} />
          </CardFooter>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardDescription>Total Expense so far</CardDescription>
            <CardTitle className="text-4xl">
              €{totalAmountSpent.toFixed(1)}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-xs text-muted-foreground">
              {percentageIncrease}% from last month
            </div>
          </CardContent>
          <CardFooter>
            <Progress aria-label="12% increase" value={12} />
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <CardDescription>Expense Chart</CardDescription>
          </CardHeader>
          <CardContent>
            <canvas id="myChart" style={{ width: "100%", height: "auto" }}></canvas>
          </CardContent>
        </Card>
        <DuplicateRemover />
      </div>
  );
}

export default Dashboard;
