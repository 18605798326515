import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Progress } from "../../@/components/progress";

const facts = [
  "Healthy fats are essential for good diet",
  "Appetites are increasing, and we're eating bigger portions",
  "Sugary foods can be more addictive than cocaine",
  "We move less and sit around more",
  "Counting calories is important, but not critical",
  "More than 10 ingredients is not ideal",
  "Don't let yourself get distracted by marketing terms like fitness, sugarfree, low-fat, etc. They often hide unhealthy components",
  "Orange juice is bad for you, but oranges are good",
  "Low-fat products often have more sugar or other unhealthy ingredients",
  "The healthiest meal is cooked at home",
  "Eat twice as many vegetables as meat in every meal",
  "Sugar with fibers is better than sugar without fibers.",
  "Less is more: Avoid too many ingredients in your meal",
  "Match every hour of screen time with exercise time",
  "If you can't pronounce the name of the ingredient, it's not healthy",
  "Imitation products often contain many unhealthy ingredients, like vegan cheese, vegetarian salami, etc"
];

function RandomFact() {
  const [currentFact, setCurrentFact] = useState("");

  useEffect(() => {
    const getRandomFact = () => {
      const randomIndex = Math.floor(Math.random() * facts.length);
      setCurrentFact(facts[randomIndex]);
    };

    getRandomFact(); // Set initial fact
    const factInterval = setInterval(getRandomFact, 5000); // Change fact every 5 seconds

    return () => clearInterval(factInterval);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      key={currentFact}
      className="text-center px-4 mb-4 h-16 flex items-center justify-center"
    >
      {currentFact}
    </motion.div>
  );
}

function ProgressDemo({ textList }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (textList) {
      const keys = Object.keys(textList);
      const values = Object.values(textList);
      const totalTime = values.reduce((acc, curr) => acc + curr, 0);
      const startTime = new Date().getTime();

      const updateProgress = () => {
        const currentTime = new Date().getTime();
        const newProgress = ((currentTime - startTime) / totalTime) * 100;
        setProgress(newProgress);
      };

      const timer = setInterval(updateProgress, 500);

      return () => clearInterval(timer);
    }
  }, [textList]);

  return (
    <Progress value={progress} />
  );
}

export default function Loading({ textList }) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % (textList ? Object.keys(textList).length : 1));
    }, textList ? Object.values(textList)[currentTextIndex] : 0);

    return () => clearInterval(interval);
  }, [textList, currentTextIndex]);

  return (
    <div className="w-screen h-screen bg-white z-20 fixed top-0 grid place-items-center">
      <div className="flex flex-col items-center gap-y-8 max-w-xs">
        <RandomFact />
        <img src='/loadingCookAnimation.gif' alt="loading" className="mb-2 w-64" />
        <h1 className="text-center flex gap-3 font-semibold">
          {textList ? Object.keys(textList)[currentTextIndex] : "Loading..."}
        </h1>

        <ProgressDemo textList={textList} />
        
        <h1 className="text-center flex gap-1 text-sm top-3/4" style={{ fontStyle: "italic" }}>
          Please don't refresh, the process might take up to 1 minute
        </h1>
      </div>
    </div>
  );
}