import { useEffect } from "react";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

const filterData = (dataForPlotting, startDate, endDate) => {
   return dataForPlotting.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });
};

const filterForLastNRecords = (dataForPlotting, n) => {
  return dataForPlotting.slice(-n);
};

// filter last n records but hard limit of 1st jan 2024, meaning anythign before this hardlimit will be filtered out 
const filterForLastNRecordsWithHardLimit = (dataForPlotting, n) => {
  const hardLimit = new Date("2024-01-01");
  // first filter out last n records
  const filteredData = filterForLastNRecords(dataForPlotting, n);
  // then filter out records before hardLimit
  return filteredData.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= hardLimit;
  });
};

export const useBarplot = (dataForPlotting, startDate, endDate) => {

  useEffect(() => {
    // check if there is atleast one data in the dataForPlotting array

    const ctx = document.getElementById("myChart").getContext("2d");

    // const filteredData = filterData(dataForPlotting, startDate, endDate);
    const filteredData = filterForLastNRecordsWithHardLimit(dataForPlotting, 10);

    //   get unique supermarkets
    const uniqueSupermarkets = [
      ...new Map(filteredData.map((item) => [item.supermarket, item])).values(),
    ];

    //   annotation based on startDate and endDate. Dotted line on all Mondays between these two dates
    const annotations = [];
    const startDateDay = startDate.getDay();
    let currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + ((1 - startDateDay + 7) % 7)); // Find the first Monday on or after the start date

    while (currentDate <= endDate) {
      if (currentDate.getDay() === 1) {
        // Check if the current date is a Monday
        annotations.push({
          type: "line",
          scaleID: "x",
          value: new Date(currentDate),
          borderColor: "rgba(0, 0, 0, 0.5)",
          borderWidth: 0.5,
          borderDash: [5, 5], // Dotted line style
          label: {
            enabled: true,
            content: "New Week",
            position: "end",
          },
        });
      }
      currentDate.setDate(currentDate.getDate() + 7); // Move to the next Monday
    }

    if (filteredData.length > 0) {
      const myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: filteredData.map((item) => new Date(item.date)),
          datasets: [
            {
              label: "Amount Spent",
              data: filteredData.map((item) => item.amount),
              backgroundColor: uniqueSupermarkets.map(
                (item, index) => `hsla(${(index * 20) % 360}, 50%, 70%, 0.8)`
              ),
              barThickness: 20, // Set the thickness of the bars to 20 pixels
              barPercentage: 0.8, // Set the bar percentage to create gaps between bars
              barSpacing: 5,
              borderRadius: 2,
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: "time",
              time: {
                parser: "date-fns", // Use date-fns as the date adapter
                unit: "day",
                displayFormats: {
                  day: "EEE d MMM", // Format the date as Tue 11 Mar
                },
              },
              // beginAtZero: true,
              ticks: {
                maxRotation: 90, // Make the x axis ticks vertical
                minRotation: 90, // Make the x axis ticks vertical
                font: {
                  size: 8,
                },
                align: "left",
              },
              grid: {
                display: true,
              },
            },
            y: {
              display: true, // Hide the y axis
              ticks: {
                display: false,
                stepSize: 25, // Show lines at intervals of 50
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: (tooltipItems) => {
                  const index = tooltipItems[0].dataIndex;
                  return `Supermarket: ${filteredData[index].supermarket}`;
                },
                label: (tooltipItem) => {
                  const index = tooltipItem.dataIndex;
                  const date = new Date(filteredData[index].date);
                  const formattedDate = date.toLocaleDateString(); // Format the date as needed
                  return `Date: ${formattedDate} - $${tooltipItem.raw}`;
                },
              },
            },
            annotation: {
              annotations: annotations,
            },
          },
        },
      });

      // Destroy the chart once it is mounted
      return () => {
        myChart.destroy();
      };
    }
  }, [dataForPlotting, startDate, endDate]);
};
