// Import necessary libaries
import React from "react";
import AutocompleteInput from "./AutoCompleteInput";
import { SingleReceiptMetrics } from "./SingleReceiptMetrics";

// Create a function called ReceiptTextDetail
const ReceiptTextDetail = ({
  store_name,
  totalAmount,
  shoppingDate,
  numberOfItems,
  numberOfCategories,
  nonFoodItems,
  essentialItems,
  userSelectedAddress,
  averageIndulgence,
  numberOfVeganItems,
  numberOfOrganicItems,
  averageSugaryRating,
}) => {
  // assign a logo based on store name
  let storeLogo = "";
  if (store_name === "Cactus") {
    storeLogo = "/cactus.png";
  }
  return (
    <div className="w-11/12 max-w-3xl m-auto text-center flex flex-col items-center gap-10">
      <SingleReceiptMetrics
        numberOfItems={numberOfItems || 0}
        totalAmount={totalAmount || 0}
        numberOfFoodItems={numberOfItems - nonFoodItems}
        numberOfLuxuryItems={numberOfItems - essentialItems}
        shoppingDate={shoppingDate || ""}
        numberOfCategories={numberOfCategories || 0}
        numberOfSugaryItems={0}
        protein={0}
        itemChange={0}
        currencySign="$"
        numberOfVeganItems={numberOfVeganItems || 0}
        numberOfOrganicItems={numberOfOrganicItems || 0}
        averageSugaryRating={averageSugaryRating || 0}
      />

      <div className="flex gap-3 text-sm text-center font-semibold px-4">
        <AutocompleteInput onAddressSelected={userSelectedAddress} />
      </div>
    </div>
  );
};

export default ReceiptTextDetail;
