import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../@/components/card";
import { Badge } from "../../@/components/badge";
import { Button } from "../../@/components/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../@/components/carousel";

const RecipeCard = ({ recipe }) => (
  <Card className="w-full max-w-[300px] mx-auto">
    <CardHeader>
      <CardTitle className="text-lg">{recipe.name}</CardTitle>
      <CardDescription>{recipe.origin}</CardDescription>
    </CardHeader>
    <CardContent>
      <div className="space-y-2 text-sm">
        <div className="flex justify-between">
          <span>Prep time:</span>
          <span>{recipe.prep_time}</span>
        </div>
        <div className="flex justify-between">
          <span>Total time:</span>
          <span>{recipe.total_time}</span>
        </div>
        <div>
          <h4 className="font-semibold mb-1">Ingredients:</h4>
          <div className="flex flex-wrap gap-1">
            {recipe.ingredients.map((ingredient, index) => (
              <Badge key={index} variant="outline" className="text-xs">
                {ingredient}
              </Badge>
            ))}
          </div>
        </div>
      </div>
    </CardContent>
    <CardFooter>
      <Button
        className="w-full text-sm"
        onClick={() =>
          window.open(
            `https://www.google.com/search?q=${encodeURIComponent(recipe.name)} recipe`,
            "_blank"
          )
        }
      >
        View Recipe
      </Button>
    </CardFooter>
  </Card>
);

const RecipeCarousel = ({ recipes }) => {
  if (!recipes || recipes.length === 0) {
    return null;
  }

  return (
    <div className="w-full max-w-sm mx-auto px-4">
      <h2 className="text-xl font-semibold mb-4 text-center">
        Dishes to make from what you just bought
      </h2>
      <Carousel className="w-full">
        <CarouselContent>
          {recipes.map((recipe, index) => (
            <CarouselItem key={index}>
              <div className="p-1">
                <RecipeCard recipe={recipe} />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default RecipeCarousel;
