import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { auth, provider } from "./config";
import { useState } from "react";

const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((data) => {
      localStorage.setItem("email", data.user.email);
      localStorage.setItem("userUid", data.user.uid);
      localStorage.setItem("isAdmin", false);

      if (data.user.email) {
        const adminEmails = require("../config").adminEmails;
        if (adminEmails) {
          const isAdmin = adminEmails.includes(data.user.email);
          localStorage.setItem("isAdmin", isAdmin);
        }
      }
      window.location.href = `/track-food/dashboard/${data.user.uid}`;
    })
    .catch((err) => console.log("error happened while login with google", err));
};

const sendPassResetEmail = (email, setMessage) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log(
        "Password reset email sent successfully. Please check your email to reset your password."
      );
      setMessage("Please check your email to reset your password.");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(
        "Error sending password reset email:",
        errorCode,
        errorMessage
      );
    });
};

const sendVerificationEmail = (
  email,
  actionCodeSettings,
  setError,
  setMessage
) => {
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      console.log(
        "Verification email sent successfully. Please check your email to verify your account."
      );
      setMessage("Please check your email to verify your account.");
      setError("");
    })
    .catch((error) => {
      console.error("Error sending verification email:", error);
      setError("Error sending verification email:", error);
    });
};
const passwordlessSignIn = (email, setMessage) => {
  let url;
  if (process.env.NODE_ENV === "production") {
    console.log("running in production");
    url = "https://www.5outapp.com/track-food/dashboard/null";
  } else {
    console.log("running in development");
    url = "http://localhost:3000/track-food/dashboard/null";
  }

  const actionCodeSettings = {
    url: url,
    handleCodeInApp: true,
  };

  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      console.log(
        "Verification email sent successfully. Please check your email to verify your account."
      );
      setMessage("Please check your email to verify your account.");
      window.localStorage.setItem("emailForSignIn", email); // Save the email locally for future use
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt("Please provide your email for confirmation");
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user by importing getAdditionalUserInfo
            // and calling it with result:
            // getAdditionalUserInfo(result)
            // You can access the user's profile via:
            // getAdditionalUserInfo(result)?.profile
            // You can check if the user is new or existing:
            // getAdditionalUserInfo(result)?.isNewUser
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(
        "Error sending verification email:",
        errorCode,
        errorMessage
      );
    });
};

const signInWithEmailPassword = (email, password, setError, setMessage) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      localStorage.setItem("email", userCredential.user.email);
      localStorage.setItem("userUid", userCredential.user.uid);
      console.log("Account created successfully. Please verify your email.");

      const actionCodeSettings = {
        // url: 'https://www.example.com/finishSignUp?cartId=1234',
        url:
          "http://localhost:3000/track-food/dashboard/" +
          userCredential.user.uid,
        handleCodeInApp: true,
      };

      sendVerificationEmail(email, actionCodeSettings, setError, setMessage);
    })
    .catch((error) => {
      if (error.code === "auth/email-already-in-use") {
        console.log("Email already in use. Trying to sign in instead.");
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            localStorage.setItem("email", userCredential.user.email);
            localStorage.setItem("userUid", userCredential.user.uid);
            console.log("Signed in successfully.");
            if (userCredential.user.email) {
              const adminEmails = require("../config").adminEmails;
              if (adminEmails) {
                const isAdmin = adminEmails.includes(userCredential.user.email);
                localStorage.setItem("isAdmin", isAdmin);
              }
            }
            window.location.href = `/track-food/dashboard/${userCredential.user.uid}`;
          })
          .catch((signInError) => {
            console.error(
              "Error signing in, but email is already in use:",
              signInError
            );
            setError("Wrong email or password. Reset your password.");
          });
      } else if (error.code === "auth/invalid-email") {
        console.log(
          "Invalid email format. Please enter a correct email address."
        );
        setError("Invalid email format. Please enter a correct email address.");
      } else if (error.code === "auth/weak-password") {
        console.log("Weak password. Password should be at least 6 characters.");
        setError("Weak password. Password should be at least 6 characters.");
      } else {
        console.log(`Error creating account: ${error.code} - ${error.message}`);
      }
    });
};

export {
  signInWithGoogle,
  signInWithEmailPassword,
  sendVerificationEmail,
  sendPassResetEmail,
  passwordlessSignIn,
};
