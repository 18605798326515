import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { getLocationFromBrowser } from "../../utils/geoLocation";

const AutocompleteInput = ({ onAddressSelected }) => {
  const [queryy, setQuery] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [placeClicked, setPlaceClicked] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [initialLocation, setInitialLocation] = useState({
    latitude: 0,
    longitude: 0,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    // Fetch predictions from Google Maps Places API
    if (value) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: value,
          origin: new window.google.maps.LatLng(initialLocation.latitude, initialLocation.longitude), // inital guess
        }, 
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(predictions);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  useEffect(() => {
    // call geoLocation
    getLocationFromBrowser().then((location) => {
      if (location && location.latitude && location.longitude) {

        setInitialLocation({
          latitude: location.latitude,
          longitude: location.longitude,
        });
      } else {
        console.error("Failed to get user's location from browser.");
      }
    });
  }, []);

  const handleClick = async (address) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const selectedAddress = results[0].formatted_address;
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();
          const placeId = results[0].place_id;

          const userPlace = {
            address: address,
            selectedAddress: selectedAddress,
            latitude: latitude,
            longitude: longitude,
            placeId: placeId,
          };


          // print userPlace
          console.log("User Place:", userPlace);

          setPlaceClicked(true);
          onAddressSelected(userPlace); // Call the callback with the full address details
          setPredictions([]); // Clear predictions after selection
          setSelectedAddress(address);
          localStorage.setItem("selectedAddress", address);
          console.log("Place added successfully!");
        } else {
          console.error("Geocoding failed with status: " + status);
        }
      });
    } catch (error) {
      console.error("Error adding place:", error);
    }
  };

  // const location = getLocationFromBrowser();

  return (
    <div className="relative w-full">
      <div className="border px-4 py-2 rounded-lg bg-white w-full md:w-[500px] m-auto  no-underline">
        <label className="bg-white flex items-center gap-3">
          <SearchIcon color="disabled" className="bg-white" />
          <input
            type="text"
            value={queryy}
            onChange={handleChange}
            className=" bg-white outline-none w-full"
            placeholder="Let's find your grocery store..."
          />
        </label>
      </div>

      {predictions.length > 0 && (
        <ul className="m-auto bg-white z-20  p-3 border border-t-0 shadow rounded-xl w-[90%] right-[5%] lg:w-9/12 absolute top-10 lg:right-[12.5%]">
          {predictions.map((prediction) => (
            <li
              onClick={() => handleClick(prediction?.description)}
              key={prediction.place_id}
              className="border-b hover:bg-gray-100 py-2 cursor-pointer"
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}

      {selectedAddress && (
        <div className="selected-address">
          Selected Address: {selectedAddress}
        </div>
      )}
    </div>
  );
};

export default AutocompleteInput;
