import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../@/components/select";
import { filterReceiptsByDate } from "../../utils/firestoreUtils";
import { ShoppingBasket, FileUp } from "lucide-react";

export const getFilteredReceipts = (newFilter, selectedDate, user) => {
  let filteredReceipts = [];
  if (newFilter === undefined) {
    newFilter = "uploadTime";
  }
  try {
    filteredReceipts = filterReceiptsByDate(selectedDate, newFilter, user);
  } catch (error) {
    console.error("Error filtering receipts:", error);
  }
  return filteredReceipts;
};

export const renderSelect = (currentFilter, setCurrentFilter) => {
  const handleFilterChange = (newFilter) => {
    setCurrentFilter(newFilter);
  };

  return (
    // Add a text saying "Filter based on"
    <>
      <div className="flex items-center justify-center mb-4"></div>
      <p className="text-sm text-gray-500">Filter based on</p>
      <Select onValueChange={handleFilterChange} value={currentFilter}>
        <SelectTrigger className="w-[280px]">
          {currentFilter ? (
            <SelectValue>
              {currentFilter === "ShoppingTime"
                ? <><ShoppingBasket className="inline-block h-5 w-5 text-gray-500" /> Shopping Date</>
                : <><FileUp className="inline-block h-5 w-5 text-gray-500" /> Upload Date</>}
            </SelectValue>
          ) : (
            <SelectValue placeholder="Filter on" />
          )}
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="ShoppingTime">Shopping Date</SelectItem>
          <SelectItem value="uploadTime">Upload Date</SelectItem>
        </SelectContent>
      </Select>
    </>
  );
};
