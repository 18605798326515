import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import sampleMarkdown from "../md-files/privacy-policy.md"; // Import your .md file

// ---------------------------------------------------------
export default function PrivacyPolicy() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(sampleMarkdown)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);
  return (
    <>
      <div className="py-4">
        {/* <h1 className=" font-bold text-2xl mb-12 ml-8">FiveOut</h1> */}
        <div className="bg-gray-800 text-center text-white py-10">
          <p className="p-4 tracking-wider mb-8">Last updated: December 31, 2023</p>
          <h1 className=" text-4xl sm:text-6xl">Privacy Policy</h1>
        </div>
        <ReactMarkdown className={"custom-content mx-4 sm:mx-8 lg:mx-12 mt-10"}>{content}</ReactMarkdown>
      </div>

    </>
  );
}
