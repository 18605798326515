import React from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardFooter } from "../../../@/components/card";
import { Button } from "../../../@/components/button";
import { UploadImage } from "../../../pages/TrackFoods";

const AddReceiptCard = () => {
  return (
    <Card className="h-full w-full" >
      <CardHeader className="pb-3">
        <CardTitle>Add a receipt</CardTitle>
        <CardDescription className="max-w-lg text-balance leading-relaxed">
          You can add a new receipt by uploading its photo.
        </CardDescription>
      </CardHeader>
      <CardFooter>
      <UploadImage buttonText="Add more" />
      </CardFooter>
    </Card>
  );
};

export default AddReceiptCard;